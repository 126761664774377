import React, { FC } from 'react';
import Button from '@material-ui/core/Button';

export interface ClaimButton {
  onClaim: () => any;
  hasClaimed: boolean;
}
export const ClaimButton: FC<ClaimButton> = ({ onClaim, hasClaimed }) => {
  return (
    <Button fullWidth color="primary" variant="contained" onClick={onClaim} disabled={hasClaimed}>
      Claim reward
    </Button>
  );
};

export default ClaimButton;
