import React from 'react';

import { Container, Typography, Box, Grid } from '@material-ui/core';

import { RaffleGrid } from '../components';
import InstructionBox from '../components/InstructionsBox';
// import { Link } from 'react-router-dom';

/**
 * Public view for the raffle page. With a wallet unlocked this should be the
 * primary view for all clients. Admins should have an extra link/button to access
 * the admin page(s) to be able to manage the contract.
 */
export const RaffleHome: React.FC = () => (
  <Container maxWidth="lg">
    <Box>
      <Typography color="textPrimary" align="center" variant="h2" gutterBottom>
        Raffle
      </Typography>
    </Box>
    <Box mt={5}>
      {/* <Grid container justify="center" spacing={3}>
        <Grid item xs={12} sm={6}> */}
          <InstructionBox/>
        {/* </Grid>
      </Grid> */}
    </Box>

    <Box mt={10}>
      <RaffleGrid />
    </Box>
  </Container>
);

export default RaffleHome;
