import type { Variant as TypoVariant } from '@material-ui/core/styles/createTypography';
import React, { FC, useState, useCallback, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment';

import { RaffleState } from '../../enums';
import utils from '../../utils';

export interface RaffleTimer {
  start: moment.Moment;
  end: moment.Moment;
  textSize?: TypoVariant;
  timerSize?: TypoVariant;
  state?: RaffleState;
  text?: string;
  hideCaption?: boolean;
  hideText?: boolean;
  inline?: boolean;
}

export const RaffleTimer: FC<RaffleTimer> = ({
  text,
  start,
  end,
  inline,
  hideText,
  state = null,
  hideCaption = false,
  timerSize = 'h3',
  textSize = 'h5',
}) => {
  const [caption, setCaption] = useState<string>('---');
  const [timer, setTimer] = useState<string>('--:--:--');

  const updateWithState = (state: RaffleState, start: moment.Moment, end: moment.Moment) => {
    const now = moment();
    const { CREATED, STARTED, ENDED, CLOSED } = RaffleState;
    switch (state) {
      case CREATED:
        setCaption('starts in');
        setTimer(utils.getTimeDelta(start, now).join(':'));
        break;
      case STARTED:
        setCaption('ends in');
        setTimer(utils.getTimeDelta(now, end).join(':'));
        break;
      case ENDED:
      case CLOSED:
        setCaption('ended');
        setTimer(end.fromNow());
        break;
    }
  };
  const updateWithTime = (start: moment.Moment, end: moment.Moment) => {
    const now = moment();
    const started = start.diff(now) < 0;
    const ended = end.diff(now) <= 0;
    setCaption(started && !ended ? 'Ends in' : ended ? 'Ended' : 'Starts in');
    if (!started) {
      setTimer(utils.getTimeDelta(now, start).join(':'));
    } else if (started && !ended) {
      setTimer(utils.getTimeDelta(now, end).join(':'));
    } else {
      setTimer(end.fromNow());
    }
  };
  const updateTime = useCallback(() => {
    if (state != null) {
      updateWithState(state, start, end);
    } else {
      updateWithTime(start, end);
    }
  }, [start, end, state]);

  useEffect(() => {
    updateTime();
    const interval = setInterval(() => updateTime(), 1000);
    return () => clearInterval(interval);
  }, [start, updateTime]);

  const finalText = `${text} ${hideCaption ? '' : caption}`.toUpperCase();

  const showTime = state != null ? state <= RaffleState.STARTED : true;

  return (
    <>
      {!hideText && !inline && (
        <Typography align="center" variant={textSize}>
          {finalText}
        </Typography>
      )}
      {showTime && <Typography align="center" variant={timerSize}>
        {!hideText && inline && finalText} {timer}
      </Typography>}
    </>
  );
};

export default RaffleTimer;
