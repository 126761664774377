import React, { Suspense, useCallback, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import RefreshIcon from '@material-ui/icons/Refresh';

import { useRaffleApi, useRaffle } from '../../hooks';
import { RaffleDataJS } from '../../types';
import { Raffle } from '../../api/Raffle';

const Header = ['Address', 'Starts At', 'Ends At', 'Total Tickets', 'Owned Tickets', 'Reward Groups'];
const GroupHeader = ['ID', 'token', 'amount', 'max winners'];

/** normalizes data from a Raffle to be displayed on the table */
const createRowData = (raffle: RaffleDataJS, dateFormat = 'YYYY-MM-DD HH:mm:ss') => ({
  id: raffle.address,
  start: raffle.startsAt.format(dateFormat),
  end: raffle.endsAt.format(dateFormat),
  total: raffle.totalTickets,
  owned: raffle.ownedTickets,
  prizeGroups: raffle.prizeGroups,
  groupsCount: raffle.prizeGroups.length,
});

export const RaffleTable = () => {
  const raffleApi = useRaffleApi();
  const [rafflesAddrs, setAddresses] = React.useState<string[]>([]);
  // const [raffles, setRaffles] = React.useState<Raffle[]>([]);
  // const [rows, setRows] = React.useState<ReturnType<typeof createRowData>[]>([]);

  useEffect(() => {
    raffleApi?.getAllRaffleAddresses().then(setAddresses);
  }, [raffleApi]);

  // useEffect(() => {
  //   if (!rafflesAddrs?.length) return;
  //   Promise.all(rafflesAddrs.map(addr => raffleApi.getRaffle(addr))).then(setRaffles)
  // },[rafflesAddrs])

  // // TODO: This "doesn't work". need to pass the address to each row and let it
  // // handle the raffle by itself with the useRaffle hook so we get up to date data
  // // whenever the raffle refreshes.
  // useEffect(() => {
  //   if (!raffles?.length) return;
  //   raffles.map(raffle => setRows(rows => [...rows, createRowData(raffle?.toJS())] ))
  // }, [raffles])

  return <RaffleTableView addresses={rafflesAddrs} />;
};

export default RaffleTable;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const LoadingRow = () => (
  <TableRow>
    <TableCell colSpan={Header.length}>
      <Typography variant="h6">Loading</Typography>
    </TableCell>
  </TableRow>
);

export const RaffleTableView: React.FC<{ addresses: string[] }> = ({ addresses }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} style={{ marginTop: '32px' }}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell /> {/* filler for action button */}
            {Header.map((header) => (
              <TableCell key={header} style={{ textAlign: 'center' }}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <Suspense fallback={<LoadingRow />}>
            {addresses.map((address) => (
              <RaffleTableRow key={address} address={address} />
            ))}
          </Suspense>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// TODO: cleanup with styles
export const RaffleTableRow: React.FC<{ address: string }> = ({ address }) => {
  const classes = useRowStyles();
  const raffle = useRaffle(address);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState<ReturnType<typeof createRowData>>();

  const updateData = useCallback(() => raffle && setData(createRowData(raffle.toJS())), [raffle]);

  useEffect(() => {
    updateData();
  }, [raffle, setData, address]);

  if (!data) return null;

  const { prizeGroups } = data ?? {};

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <IconButton aria-label="refresh" size="small" onClick={updateData}>
            <RefreshIcon />
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {data.id}
        </TableCell>
        <TableCell align="right">{data.start}</TableCell>
        <TableCell align="right">{data.end}</TableCell>
        <TableCell align="right">{data.total}</TableCell>
        <TableCell align="right">{data.owned}</TableCell>
        <TableCell align="right">{data.groupsCount}</TableCell>
      </TableRow>
      {/* Collapsed reward groups */}
      <TableRow style={{ borderBottom: '1px solid rgb(81,81,81)' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Reward Groups
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {GroupHeader.map((field) => (
                      <TableCell style={{ textAlign: 'center', border: 'none' }} key={field}>
                        {field}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prizeGroups.map((group) => (
                    <TableRow key={group.address}>
                      <TableCell style={{ textAlign: 'center', border: 'none' }} component="th" scope="row">
                        {group.address}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center', border: 'none' }}>{group.symbol}</TableCell>
                      <TableCell style={{ textAlign: 'center', border: 'none' }} align="right">
                        {group.amount}
                      </TableCell>
                      <TableCell style={{ textAlign: 'center', border: 'none' }} align="right">
                        {group.winners}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
