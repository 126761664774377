// import { ChainId } from '@pancakeswap-libs/sdk';
import { ChainId } from '@spookyswap/sdk';
import { Configuration } from './tomb-finance/config';
import { BankInfo } from './tomb-finance';
import { createRaffleConfig } from './modules/Raffle';

type ENVIRONMENT = 'development'|'production'|'test';
// helper methods to get environment and correct network setup
// const getEnvironment = (): ENVIRONMENT => 'production';
const getEnvironment = (): ENVIRONMENT => process.env.NODE_ENV || 'production';
const onTestnet = () => getEnvironment() === 'development';


const configurations: { [env: string]: Configuration } = {
  development: {
    // chainId: ChainId.FTMTESTNET,
    // networkName: 'Fantom Opera Testnet',
    // ftmscanUrl: 'https://testnet.ftmscan.com',
    // defaultProvider: 'https://rpc.testnet.fantom.network/',
    // deployments: require('./tomb-finance/deployments/deployments.testing.json'),
    // externalTokens: {
    //   WFTM: ['0xf1277d1ed8ad466beddf92ef448a132661956621', 18],
    //   FUSDT: ['0xb7f24e6e708eabfaa9e64b40ee21a5adbffb51d6', 6],
    //   WETH: ['0x14f0C98e6763a5E13be5CE014d36c2b69cD94a1e', 18],
    //   TOMB: ['0x2317610e609674e53D9039aaB85D8cAd8485A7c5', 0],
    //   MIM: ['0x39523112753956d19A3d6a30E758bd9FF7a8F3C0', 9],
    //   'USDT-FTM-LP': ['0xE7e3461C2C03c18301F66Abc9dA1F385f45047bA', 18],
    //   '2OMB-FTM-LP': ['0x13Fe199F19c8F719652985488F150762A5E9c3A8', 18],
    //   '2SHARE-FTM-LP': ['0x20bc90bB41228cb9ab412036F80CE4Ef0cAf1BD5', 18],
    //   '2OMB-2SHARE-LP': ['0xd9B5f00d183df52D717046521152303129F088DD', 18],
    //   // Redemption LPs
    //   '2OMB-WFTM R-LP': ['0x3b602c7ed7f9318926ffbf767e5dc838ca210085',18],    // red v2 LP
    //   '2SHARES-WFTM R-LP': ['0x53a8a772044d7c99611382064e6ad6bbcf41fc8e',18], // red v2 LP
    //   // TODO: Add 2OMB-2SHARES R-LP
    // },
    // baseLaunchDate: new Date('2021-06-02 13:00:00Z'),
    // bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
    // masonryLaunchesAt: new Date('2020-12-11T00:00:00Z'),
    // refreshInterval: 10000,
    chainId: ChainId.MAINNET,
    networkName: 'Fantom Opera Mainnet',
    ftmscanUrl: 'https://ftmscan.com',
    defaultProvider: 'https://rpc.ftm.tools/',
    deployments: require('./tomb-finance/deployments/deployments.mainnet.json'),
    externalTokens: {
      WFTM: ['0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83', 18],
      FUSDT: ['0x1B6382DBDEa11d97f24495C9A90b7c88469134a4', 6], // This is actually usdc on mainnet (axlUSDC)
      WETH: ['0x74b23882a30290451A17c44f4F05243b6b58C76d', 18], // BOO: 0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE 18
      TOMB: ['0x6c021ae822bea943b2e66552bde1d2696a53fbb7', 18], // ZOO: 0x09e145a1d53c0045f41aeef25d8ff982ae74dd56 0
      MIM: ['0x82f0b8b456c1a451378467398982d4834b6829c1', 18], // SHIBA: 0x9ba3e4f84a34df4e08c112e1a0ff148b81655615 9
      'USDT-FTM-LP': ['0xC83E09ba6e22594e91814Cfb61bBE9BEbBfcE4d5', 18],
      '2OMB-FTM-LP': ['0x6c5936d58DaBC178DE25fE9b77669a16a7822A42', 18], //
      '2SHARE-FTM-LP': ['0x07Af8163f7865e5F80B5d111F105F83F0dF21c49', 18],
      '2OMB-2SHARE-LP': ['0xafE2f81475D7029aa87Af8BE83ff31bD70a76f83', 18],
      '2OMB': ['0x7a6e4E3CC2ac9924605DCa4bA31d1831c84b44aE', 18],
      '2BOND': ['0xEB32Ac9c850d059E24C40a42DBc124216149172d', 18],
      '3SHARES': ['0x6437ADAC543583C4b31Bf0323A0870430F5CC2e7', 18],
      // Redemption LPs
      '2OMB-WFTM R-LP': ['0x3B602C7Ed7F9318926Ffbf767E5dc838cA210085',18],    // red v2 LP
      '2SHARES-WFTM R-LP': ['0x53a8A772044D7c99611382064e6aD6BbCf41fC8e',18], // red v2 LP
      // TODO: Add 2OMB-2SHARES R-LP
    },
    baseLaunchDate: new Date('2021-06-02 13:00:00Z'),
    bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
    masonryLaunchesAt: new Date('2020-12-11T00:00:00Z'),
    refreshInterval: 10000,
  },
  production: {
    chainId: ChainId.MAINNET,
    networkName: 'Fantom Opera Mainnet',
    ftmscanUrl: 'https://ftmscan.com',
    defaultProvider: 'https://rpc.ftm.tools/',
    deployments: require('./tomb-finance/deployments/deployments.mainnet.json'),
    externalTokens: {
      WFTM: ['0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83', 18],
      FUSDT: ['0x1B6382DBDEa11d97f24495C9A90b7c88469134a4', 6], // This is actually usdc on mainnet (axlUSDC)
      WETH: ['0x74b23882a30290451A17c44f4F05243b6b58C76d', 18], // BOO: 0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE 18
      TOMB: ['0x6c021ae822bea943b2e66552bde1d2696a53fbb7', 18], // ZOO: 0x09e145a1d53c0045f41aeef25d8ff982ae74dd56 0
      MIM: ['0x82f0b8b456c1a451378467398982d4834b6829c1', 18], // SHIBA: 0x9ba3e4f84a34df4e08c112e1a0ff148b81655615 9
      'USDT-FTM-LP': ['0xC83E09ba6e22594e91814Cfb61bBE9BEbBfcE4d5', 18],
      '2OMB-FTM-LP': ['0x6c5936d58DaBC178DE25fE9b77669a16a7822A42', 18], //
      '2SHARE-FTM-LP': ['0x07Af8163f7865e5F80B5d111F105F83F0dF21c49', 18],
      '2OMB-2SHARE-LP': ['0xafE2f81475D7029aa87Af8BE83ff31bD70a76f83', 18],
      '2OMB': ['0x7a6e4E3CC2ac9924605DCa4bA31d1831c84b44aE', 18],
      '2BOND': ['0xEB32Ac9c850d059E24C40a42DBc124216149172d', 18],
      '3SHARES': ['0x6437ADAC543583C4b31Bf0323A0870430F5CC2e7', 18],
      // Redemption LPs
      '2OMB-WFTM R-LP': ['0x3B602C7Ed7F9318926Ffbf767E5dc838cA210085',18],    // red v2 LP
      '2SHARES-WFTM R-LP': ['0x53a8A772044D7c99611382064e6aD6BbCf41fC8e',18], // red v2 LP
      // TODO: Add 2OMB-2SHARES R-LP
    },
    baseLaunchDate: new Date('2021-06-02 13:00:00Z'),
    bondLaunchesAt: new Date('2020-12-03T15:00:00Z'),
    masonryLaunchesAt: new Date('2020-12-11T00:00:00Z'),
    refreshInterval: 10000,
  },
};

export const genesisDefinitions: { [contractName: string]: BankInfo } = {
  /*
  Explanation:
  name: description of the card
  poolId: the poolId assigned in the contract
  sectionInUI: way to distinguish in which of the 3 pool groups it should be listed
        - 0 = Single asset stake pools
        - 1 = LP asset staking rewarding TOMB
        - 2 = LP asset staking rewarding TSHARE
  contract: the contract name which will be loaded from the deployment.environmnet.json
  depositTokenName : the name of the token to be deposited
  earnTokenName: the rewarded token
  finished: will disable the pool on the UI if set to true
  sort: the order of the pool
  */
  TombFtmRewardPool: {
    name: 'Stake WFTM, earn 2OMB',
    poolId: 0,
    sectionInUI: 0,
    contract: 'TombFtmRewardPool',
    depositTokenName: 'WFTM',
    earnTokenName: '2OMB',
    multiplier: "100x",
    finished: false,
    sort: 1,
    closedForStaking: false,
  },
  TombWethRewardPool: {
    name: 'Stake wETH, earn 2OMB',
    poolId: 1,
    sectionInUI: 0,
    contract: 'TombWethGenesisRewardPool',
    depositTokenName: 'WETH',
    earnTokenName: '2OMB',
    multiplier: "50x",
    finished: false,
    sort: 2,
    closedForStaking: false,
  },
  TombShibaRewardPool: {
    name: 'Stake MIM, earn 2OMB',
    poolId: 2,
    sectionInUI: 0,
    contract: 'TombShibaGenesisRewardPool',
    depositTokenName: 'MIM',
    earnTokenName: '2OMB',
    multiplier: "25x",
    finished: false,
    sort: 3,
    closedForStaking: false,
  },
  TombTombRewardPool: {
    name: 'Stake TOMB, earn 2OMB',
    poolId: 3,
    sectionInUI: 0,
    contract: 'TombTombGenesisRewardPool',
    depositTokenName: 'TOMB',
    earnTokenName: '2OMB',
    multiplier: "100x",
    finished: false,
    sort: 4,
    closedForStaking: false,
  },
}

export const bankDefinitions: { [contractName: string]: BankInfo } = {
  /*
  Explanation:
  name: description of the card
  poolId: the poolId assigned in the contract
  sectionInUI: way to distinguish in which of the 3 pool groups it should be listed
        - 0 = Single asset stake pools
        - 1 = LP asset staking rewarding TOMB
        - 2 = LP asset staking rewarding TSHARE
  contract: the contract name which will be loaded from the deployment.environmnet.json
  depositTokenName : the name of the token to be deposited
  earnTokenName: the rewarded token
  finished: will disable the pool on the UI if set to true
  sort: the order of the pool
  */
  TombFtmRewardPool: {
    name: 'Stake WFTM, earn 2OMB',
    poolId: 0,
    sectionInUI: 0,
    contract: 'TombFtmRewardPool',
    depositTokenName: 'WFTM',
    earnTokenName: '2OMB',
    multiplier: "100x",
    finished: false,
    sort: 1,
    closedForStaking: true,
    genesisFinished: true
  },
  TombWethRewardPool: {
    name: 'Stake wETH, earn 2OMB',
    poolId: 1,
    sectionInUI: 0,
    contract: 'TombWethGenesisRewardPool',
    depositTokenName: 'WETH',
    earnTokenName: '2OMB',
    multiplier: "50x",
    finished: false,
    sort: 2,
    closedForStaking: true,
    genesisFinished: true
  },
  TombShibaRewardPool: {
    name: 'Stake MIM, earn 2OMB',
    poolId: 2,
    sectionInUI: 0,
    contract: 'TombShibaGenesisRewardPool',
    depositTokenName: 'MIM',
    earnTokenName: '2OMB',
    multiplier: "25x",
    finished: false,
    sort: 3,
    closedForStaking: true,
    genesisFinished: true
  },
  TombTombRewardPool: {
    name: 'Stake TOMB, earn 2OMB',
    poolId: 3,
    sectionInUI: 0,
    contract: 'TombTombGenesisRewardPool',
    depositTokenName: 'TOMB',
    earnTokenName: '2OMB',
    multiplier: "100x",
    finished: false,
    sort: 4,
    closedForStaking: true,
    genesisFinished: true
  },
  TombFtmLPTombRewardPool: {
    name: 'Earn 2OMB by 2OMB-WFTM',
    poolId: 0,
    sectionInUI: 1,
    contract: 'TombFtmLpTombRewardPool',
    depositTokenName: '2OMB-FTM-LP',
    earnTokenName: '2OMB',
    multiplier: "1000x",
    finished: false,
    sort: 5,
    closedForStaking: true,
  },
  TombFtmLPTombRewardPoolOld: {
    name: 'Earn TOMB by TOMB-FTM LP',
    poolId: 0,
    sectionInUI: 1,
    contract: 'TombFtmLpTombRewardPoolOld',
    depositTokenName: '2OMB-FTM-LP',
    earnTokenName: 'TOMB',
    multiplier: "0",
    finished: true,
    sort: 11,
    closedForStaking: true,
  },

  // Old spookyswap LPs
  TombFtmLPTShareRewardPool: {
    name: 'Earn 2SHARE by 2omb | FTM',
    poolId: 0,
    sectionInUI: 4,
    contract: 'TombFtmLPTShareRewardPool',
    depositTokenName: '2OMB-FTM-LP',
    earnTokenName: '2SHARE',
    multiplier: "3000x",
    finished: false,
    sort: 6,
    closedForStaking: true,
  },
  TshareFtmLPTShareRewardPool: {
    name: 'Earn 2SHARE by 2SHARE-WFTM LP',
    poolId: 1,
    sectionInUI: 4,
    contract: 'TshareFtmLPTShareRewardPool',
    depositTokenName: '2SHARE-FTM-LP',
    earnTokenName: '2SHARE',
    multiplier: "2400x",
    finished: false,
    sort: 7,
    closedForStaking: true,
  },
  TombTsharePTShareRewardPool: {
    name: 'Earn 2SHARE by 2OMB-2SHARE LP',
    poolId: 2,
    sectionInUI: 4,
    contract: 'TombTshareLPTShareRewardPool',
    depositTokenName: '2OMB-2SHARE-LP',
    earnTokenName: '2SHARE',
    multiplier: "550x",
    finished: false,
    sort: 8,
    closedForStaking: true,
  },


  TwoombondSingleTShareRewardPool: {
    name: 'Earn 2SHARES by staking 2BOND',
    poolId: 4,
    sectionInUI: 4,
    contract: 'TwoombondSingleTShareRewardPool',
    depositTokenName: '2BOND',
    earnTokenName: '2SHARE',
    finished: false,
    multiplier: '35500x',
    // site: '/',
    sort: 1,
    closedForStaking: true,
  },
  TwoombSingleTShareRewardPool: {
    name: 'Earn 2SHARES by staking 2OMB',
    poolId: 3,
    sectionInUI: 4,
    contract: 'TwoombSingleTShareRewardPool',
    depositTokenName: '2OMB',
    earnTokenName: '2SHARE',
    finished: false,
    multiplier: '35500x',
    // site: '/',
    sort: 2,
    closedForStaking: true,
  },

  //REDEMPTION FARMS
  TwoombWFTMRLPTShareRewardPool: { // new
    name: 'Earn 3SHARES by 2OMB-WFTM R-LP',
    poolId: 20,
    sectionInUI: 4,
    contract: 'TwoombWFTMRLPTShareRewardPool',
    depositTokenName: '2OMB-WFTM R-LP',
    earnTokenName: '3SHARES',
    finished: false,
    multiplier: '35500x',
    sort: 1,
    closedForStaking: true,
  },
  TwoshareWFTMRLPTShareRewardPool: { // new
    name: 'Earn 3SHARES by 2SHARES-WFTM R-LP',
    poolId: 19, // TODO: UPDATE POOL ID
    sectionInUI: 4,
    contract: 'TwoshareWFTMRLPTShareRewardPool',
    depositTokenName: '2SHARES-WFTM R-LP',
    earnTokenName: '3SHARES',
    finished: false,
    multiplier: '35500x',
    sort: 2,
    closedForStaking: true,
  },
  // SPOOKY FOR 3SHARES rewards
  TwoombWFTMSLPTShareRewardPool: { // new
    name: 'Earn 3SHARES by 2OMB-FTM-LP',
    poolId: 3,
    sectionInUI: 4,
    contract: 'TwoombWFTMSLPTShareRewardPool',
    depositTokenName: '2OMB-FTM-LP',
    earnTokenName: '3SHARES',
    finished: false,
    multiplier: '35500x',
    sort: 12,
    closedForStaking: true,
  },
  TwoshareWFTMSLPTShareRewardPool: { // new
    name: 'Earn 3SHARES by 2SHARE-FTM-LP',
    poolId: 2,
    sectionInUI: 4,
    contract: 'TwoshareWFTMSLPTShareRewardPool',
    depositTokenName: '2SHARE-FTM-LP',
    earnTokenName: '3SHARES',
    finished: false,
    multiplier: '35500x',
    sort: 3,
    closedForStaking: true,
  },
  // TshareDividends: {
  //   name: 'Earn USDC dividends by staking 2SHARES',
  //   poolId: 0,
  //   sectionInUI: 3,
  //   contract: 'TombTshareLPTShareRewardPool',
  //   depositTokenName: '2SHARES',
  //   earnTokenName: 'USDC',
  //   multiplier: "0x",
  //   finished: false,
  //   sort: 9,
  //   closedForStaking: false,
  // },
};

export const RAFFLE_CONFIG = createRaffleConfig({
  testnet: onTestnet(),
  /**
   * @dev these will get overridden by the default if we are in dev environment
   * Should not happen on preview deploy on netlify or
   */
  address: {
    factory:  '0xC213010ED4Fd1cf06141FDD26e1Eb30E207A6378',
    staking:  '0x2A990a9a754D4CAD9fa5b6918435c2A57058f8A5',
    raffle:   '0x1c7801e0dFc5cE96D50789b3DE938C2F117032C8',
    treasury: '0x1B84d55291126b934dBe5EbF47dA2754B66725C5',
    registry: '0xBFeA00152A2689c68c4A0FB53659185585Ce0D05',
  },
})

export default configurations[getEnvironment()];
// export default configurations['production'];
