import React, { useCallback } from 'react';
import { PopupWrapper, PopupIcon, PopupAction, PopupText } from './components';

export interface ErrorPopup {
  message: string;
  stack: string;
}

export default function ErrorPopup({ message, stack }: ErrorPopup) {
  const copyErrorDetails = useCallback(async () => {
    await navigator.clipboard.writeText(`${message}\n${stack}`);
  }, [message, stack]);

  return (
    <PopupWrapper>
      <PopupIcon success={false} />
      <PopupText>{message}</PopupText>
      <PopupAction text="Copy error details" href="#" onClick={copyErrorDetails} />
    </PopupWrapper>
  );
}
