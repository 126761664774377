import { BigNumber, Contract } from 'ethers';
import moment from 'moment';
import { RaffleState } from '../enums';
import {
  RaffleDataJS,
  RaffleContracts,
  RaffleData,
  RaffleItem,
  // SettableState,
  RaffleParams,
  RaffleTimes,
  RafflePrizeGroup,
} from '../types';
import type { RaffleApi } from './Raffle.api';
import utils from './utils';
import { TransactionResponse } from '@ethersproject/providers';
import ERC20 from '../../../tomb-finance/ERC20';
import errors from './errors';

const STATES_ENDED = [RaffleState.ENDED, RaffleState.CLOSED];

/**
 * Raffle wrapper object.
 * TODO: Move to separate file
 */
export class Raffle implements RaffleItem {
  /** prevents multiple initialize calls */
  private __initialized = false;
  private _isAdmin: boolean = false;
  /** ether contract for the raffle */
  public contract: Contract;
  /** fetched and (mostly) updated raw data for the raffle */
  public data: RaffleData;
  public utils: typeof utils;
  private _subscribedToUnlock: boolean = false;

  /** @dev hack for react to pick up updates */
  public _refreshes: number = 0;

  constructor(public address: string, private _api: RaffleApi) {
    this.utils = utils;
    this.data = utils.getEmptyData();

    this.contract = new Contract(address, _api.config.abi.raffle, _api.signer);
    this._updateData({ address });
  }

  /** UI usable data for this raffle. evaluated on request */
  public toJS(from_?: RaffleData): RaffleDataJS {
    return utils.raffleDataToJS(from_ ?? this.data);
  }
  public get api(): RaffleApi {
    return this._api;
  }
  public get account(): string {
    return this.api.account;
  }
  public get isReady(): boolean {
    return this.__initialized;
  }
  /**
   * Check whether the raffle has been properly set up and is usable
   * on the front end. this can be false if the tokens have not been set
   * properly (null addresses) or any other errors.
   */
  public get isValid(): boolean {
    return this.__initialized
      && !utils.isNullAddr(this.data.utilityToken)
      && !utils.isNullAddr(this.data.treasuryContract)
      && !utils.isNullAddr(this.data.stakingContract);
  }
  public get refreshes(): number {
    return this._refreshes;
  }
  public get accountIsAdmin(): boolean {
    // contracts have their own access control system and registered admins,
    // so calling the RaffleApi admin method is not the best way of doing it
    return this._isAdmin;
  }

  public get stakingContract(): Contract {
    return this.api.getStakingContract(this.data.stakingContract);
  }

  public get treasuryContract(): Contract {
    return this.api.getTreasuryContract(this.data.treasuryContract);
  }

  public get utilityToken(): ERC20 {
    return this.api.getToken(this.data.utilityToken);
  }

  public get stakingToken(): ERC20 {
    return this.api.getToken(this.data.stakingToken);
  }

  /** true if user has something staked and startTime is in the past */
  public get canBuyTickets(): boolean {
    return (
      this.data.stakedAmount.gt(0) &&
      // TODO: check raffle status === STARTED &&
      moment.unix(this.data.startTime.toNumber()).isBefore(moment())
    );
  }
  /** true if the user some stakes and the lock time is in the past */
  public get canUnstake(): boolean {
    return (
      this.data.stakedAmount.gt(0) &&
      moment.unix(this.data.lockEnds.toNumber()).isBefore(moment())
    )
  }
  /**
   * Queries the raffle contract for the total cost of the desired tickets count.
   * @param ticketsCount amount of tickets to buy
   * @returns {number} total cost for all the tickets
   */
  public async getTicketsCost(ticketsCount: number): Promise<number> {
    const ticketPrice: BigNumber = await this.contract.costToBuyTickets(BigNumber.from(ticketsCount));
    return utils.BnToNumber(ticketPrice, this.utilityToken.decimal);

  }
  public async getTicketsCostWithDiscountAndBurn(amount: number) {
    const data = await this.contract.costToBuyTicketsWithDiscountAndBurn(BigNumber.from(amount));
    const {cost, discount, costWithDiscount, toBurn }: {[k:string]: BigNumber} = data;
    const toNumber = (v: BigNumber): number => utils.BnToNumber(v, this.utilityToken.decimal)

    return {
      cost: toNumber(cost),
      discount: utils.fromWei(discount),
      costWithDiscount: toNumber(costWithDiscount),
      toBurn: utils.fromWei(toBurn),
    }
  }

  public async buyTickets(amount: number): Promise<TransactionResponse> {
    // if (!this.canBuyTickets) return;
    try {
      return await this.contract.buyTickets(amount);
    } catch (e) {
      utils.logger.generic(`${errors.getDisplayText(e as any)} | ${this.address}`, '⛔', 'error');
      console.error(e)
    }
  }

  public async stakeTokens(amount: number): Promise<TransactionResponse> {
    return this.stakingContract?.stake(utils.toWei(amount));
  }

  public async unstakeTokens(amount?: number): Promise<TransactionResponse> {
    if (!await this.stakingContract.canUnstake()) {
      utils.logger.generic(`Unable to unstake. Locking period not over yet`, '⛔', 'error');
    }
    try {
      if (amount) {
        return this.stakingContract?.unstake(utils.toWei(amount));
      }
      return this.stakingContract?.unstakeAll();
    } catch (e) {
      utils.logger.generic(`${errors.getDisplayText(e as any)} | ${this.address}`, '⛔', 'error');
    }
  }

  public async claimReward(): Promise<TransactionResponse> {
    // cannot claim reward if not ended
    if (!STATES_ENDED.includes(this.data.status)) {
      utils.logger.generic(`Cannot claim reward. Raffle not ended yet`, '⛔', 'error');
      return;
    }
    try {
      return this.contract.claimPrize()
    } catch (e) {
      utils.logger.generic(errors.getDisplayText(e as any), '⛔', 'error');
      console.error(e)
    }
  }
  /**
   * Initialize the raffle and fetch the current data from the contract.
   * Ensures that we have a connected wallet before calling the contract
   * so we don't get errors.
   * @internal
   */
  public async initialize(): Promise<boolean> {
    if (this.__initialized) return;
    // ensure the wallet is unlocked before calling any contract methods
    // otherwise they fail badly. This may happen if the user loads the page
    // directly from the url. and we create objects before they unlocked.
    if (!this.api.account && !this._subscribedToUnlock) {
      this.api.core.subscribeToUnlock(this.initialize.bind(this));
      this._subscribedToUnlock = true;
      return;
    }
    return this._initialize();
  }

  // ADMIN METHODS ////////////////////////////////////////////////////////////

  /**
   * Proxy for the contract methods to set the raffle's new status. Methods can
   * be called only by registered admins on the INDIVIDUAL contract and the
   * contract itself will bounce the call if the caller is not an admin.
   * @contract @admin
   * @param newState New tate to request to the contract
   * @returns nothing for now
   */
  public async setState(newState: RaffleState): Promise<TransactionResponse> {

    type StatesMap = Partial<Record<RaffleState, (...args: any[]) => Promise<TransactionResponse>>>;

    const methods: StatesMap = {
      [RaffleState.STARTED]: this.contract.startRaffle,
      [RaffleState.ENDED]: this.contract.endRaffle,
      [RaffleState.CLOSED]: this.contract.closeRaffle,
    };

    if (!this._ensureAdmin()) {
      return
      // TODO: Show the actual error in the UI
    }
    try {
      return await methods[newState]();
    } catch (error) {
      utils.logger.error(error);
      // TODO: Show the actual error in the UI
    }
    this.api.updateRaffleState(this.address, newState);
    return;
  }
  /**
   * Sets up the raffle addresses. This should be called only by admin wallets
   * and only if the raffle is in CREATED state.
   * @dev
   * FIXME: this is on the contract side. We don't have a way to grab the tax
   *  or registry addresses so this is prone to error / exploit by bad admins
   *  since we have NO way of checking what's set in the contract nor pass
   *  the current ones as a parameter of the contract call.
   * @contract @admin
   */
  public async setRaffleAddresses(addresses: Partial<RaffleContracts>): Promise<TransactionResponse> {
    if (!this._ensureAdmin() || !this._ensureState(RaffleState.CREATED)) return;

    const defaults: typeof addresses = {
      utilityToken: this.data.utilityToken,
      stakingContract: this.data.stakingContract,
      treasuryContract: this.data.treasuryContract,
      participantRegistry: this.data.participantRegistry,
      taxRecipient: this.data.taxRecipient,
    };
    addresses = { ...defaults, ...addresses };
    return await this.contract.setRaffleAddresses(
      addresses.utilityToken,
      addresses.stakingContract,
      utils.NULL_ADDRESS,
      addresses.treasuryContract,
      addresses.taxRecipient,
      addresses.participantRegistry,
    );
  }
  /**
   * Update the raffle start and end time on the contract
   * @contract @admin
   * @param startTime time for the raffle to start | unix timestamp
   * @param endTime time for the raffle end | unix timestamp
   * @returns void
   */
  public async setRaffleTimes(times:{start?: moment.Moment, end?: moment.Moment}): Promise<TransactionResponse> {
    if (!this._ensureAdmin() || !this._ensureState(RaffleState.CREATED)) return;
    const { startTime, endTime } = this.data
    const { start, end } = times
    const _t = {
      start: start ? utils.momentToBN(start) : startTime,
      end: end ? utils.momentToBN(end) : endTime,
    }
    return await this.contract.setRaffleTimes(_t.start, _t.end);
  }

  /**
   * set all the general parameters on the raffle contract
   * @contract @admin
   * TODO: Implement
   */
  public async setRaffleParams(): Promise<void> {
    if (!this._ensureAdmin() || !this._ensureState(RaffleState.CREATED)) return;
    throw new Error('Not implemented');
    /**
      uint256 _costPerTicket, // wei
      uint16[] calldata _prizeDistributionGroups, // [1, 10, 100] (group 1: 1 winner; group 2: 10 winners; group 3: 100 winners)
      address[] calldata _prizeDistributionTokens, // [0x11.., 0x231..., 0x45] (group 1: gets the 0x11.. token as prize; group 2: get 0x231... token as prize; etc)
      uint256[] calldata _prizePoolInUtilityToken, // [100000000000, 4000000000, 50000000000000] (group 1: gets that much wei in tokenl group: ...)
      uint16 _prizeDistributionGroupsSize, // 3
      uint16 _burnAmount, // percentage i.e 1
      uint256 _initialTaxAmount // percentage i.e. 50
    */
  }

  // VIEWS AND UPDATERS ///////////////////////////////////////////////////////
  /**
   * fetches the amount of owned tickets by the currently logged user
   * @contract
   * @return amount as BigNumber
   */
  public async getOwnedTickets(): Promise<BigNumber> {
    return await this.contract.getUserTicketCount(this.api.account);
  }

  /**
   * Queries the contract for all the information required to operate
   * @contract
   * @returns Current raffle configuration status
   */
  public async fetchRaffleData(): Promise<RaffleData> {
    if (!this._ensureNetwork()) {
      return;
    }
    /** @dev order of these matters. we need addresses and params first */
    const addresses   = await this._getAddresses(false);
    const params      = await this._getParams(false);
    const times       = await this._getTimes(false);
    const winInfo     = await this._getWinnerInfo(false);
    const prizeGroups = await this._getPrizeGroups(false);

    let hasClaimed = false;
    if (params.status >= RaffleState.ENDED) {
      hasClaimed = await this._getHasClaimedPrize(false);
    }
    let data: Partial<RaffleData> = {
      ...params,
      ...addresses,
      ...times,
      ...prizeGroups,
      ...winInfo,
      hasClaimed,
    };

    // Fetch the staking info from the staking contract
    if (!utils.isNullAddr(addresses.stakingContract)) {
      const _contract = this.api.getStakingContract(addresses.stakingContract)
      const stakingToken = await _contract.getStakingTokenAddress();
      const [stakedAmount, lockEnds ] = await this.getStakingValues();
      data = {...data, stakingToken, stakedAmount, lockEnds };
    } // else utils.logger.valueNotSet(this, 'staking contract');
    /**
     * triggers a refresh on the data and react components
     * @dev this is a bit of a hack, but it's the only way to update the data
     * consistently and trigger calls to the contracts to update values.
     * This should be done with events, callbacks and such
     */
    this._handleRefresh();
    return this._updateData(data);
  }

  /**
   * Queries the staking contract for the current staked amount and lock expiration
   * @returns {[number, Moment]} [stakedAmount, lockExpiration]
   */
   public async getStakingValues(): Promise<[BigNumber, BigNumber]> {
    const zero = BigNumber.from(0)
    if (utils.isNullAddr(this.data.stakingContract)) return [zero, zero]
    const [staked, lockExpires]: BigNumber[] = await this.stakingContract.getUserBalance(this.api.account);
    return [staked, lockExpires];
  }
  // INTERNALS ////////////////////////////////////////////////////////////////

  /**
   * @dev actual initialize implementation
   * @contract @internal
   */
   private async _initialize(): Promise<boolean> {
    await this.fetchRaffleData();

    this._isAdmin = await this.contract.hasRole(utils.toByte32(0), this.api.account);

    this._subscribeToStateChange('RaffleCreated', RaffleState.CREATED);
    this._subscribeToStateChange('RaffleStarted', RaffleState.STARTED);
    this._subscribeToStateChange('RaffleEnded', RaffleState.ENDED);
    this._subscribeToStateChange('RaffleClosed', RaffleState.CLOSED);

    this.contract.on('PrizeClaimed', (address: string) => {
      if (address === this.api?.account) { this.data.hasClaimed = true; }
    })
    this.contract.on('NewPurchase', (tickets, address) => {
      this.data.totalBought += tickets;
      if (address === this.api?.account) { this.data.ownedTickets += tickets; }
    })

    let interval: NodeJS.Timeout;

    if (this._ensureNetwork())
      interval = setInterval(this.fetchRaffleData.bind(this), this.api.config.pollingInterval);

    (window as any).ethereum?.on('networkChanged', (networkId: number) => {
      if (networkId !== this.api.provider.network.chainId) {
        this.contract.removeAllListeners()
        return clearInterval(interval);
      }
      this._initialize();
      interval = setInterval(this.fetchRaffleData.bind(this), this.api.config.pollingInterval);
    })

    this.__initialized = true;
    return this.__initialized;
  }

  /**
   * Get the raffle parameters from the contract
   * @sideeffect updates raffle api state for the current raffle
   * @dev TODO: move api update into separate method (and call here)
   * @contract @internal
   */
  private async _getParams(update = true): Promise<RaffleParams> {
    if (!this._ensureNetwork()) {
      return;
    }
    // [(status), (uint256), (uint256), (uint16), (uint256)]
    const [status, cost, totalBurnt, burnAmount, totalBought] = await this.contract.getRaffleParams();
    const ownedTickets = await this.getOwnedTickets();
    this.api.updateRaffleState(this.address, status)
    return this._maybeUpdateData<RaffleParams>(update, {
      status,
      totalBought,
      burnAmount,
      totalBurnt,
      ownedTickets,
      ticketCost: cost,
    })
  }
  /**
   * Get the raffle configured addresses
   * @contract @internal
   */
  private async _getAddresses(update = true): Promise<RaffleContracts> {
    if (!this._ensureNetwork()) {
      return;
    }
    // address[]
    const [
      stakingContract, utilityToken, treasuryContract, taxRecipient, participantRegistry
    ]: string[] = await this.contract.getRaffleAddresses();
    return this._maybeUpdateData<RaffleContracts>(update, {
      utilityToken,
      stakingContract,
      treasuryContract,
      taxRecipient,
      participantRegistry,
    })
  }
  /**
   * Get the raffle configured start|end times
   * @contract @internal
   */
  private async _getTimes(update = true): Promise<RaffleTimes> {
    if (!this._ensureNetwork()) {
      return;
    }
    const [startTime, endTime] = await this.contract.getRaffleTimes();
    return this._maybeUpdateData<RaffleTimes>(update, {
      startTime: startTime,
      endTime: endTime,
    })
  }
  /**
   * Queries the contract for the prize groups and return an array of objects
   * with BigNumber values.
   * @contract @internal
   * @sideeffect create ERC20 tokens in API for each reward group
   * @returns Array of all the reward groups
   */
  private async _getPrizeGroups(update = true): Promise<RafflePrizeGroupdata> {
    if (!this._ensureNetwork()) {
      return;
    }
    // [winners (uint16[]) token(address[]), ? (uint256), amount (uint256[]) ]
    const [winners, tokens, count, amounts] = await this.contract.getRafflePrizeParams();
    /** @dev as per contract specs arrays size is the same */
    if (!validatePrizeGroups(count, winners, tokens, amounts)) {
      utils.logger.prizeGroupsInvalid(this.address, 'Arrays size is not the same');
      return;
    }
    const groups: RafflePrizeGroup[] = [];
    for (let i = 0; i < count; i++) {
      const token = await this.api.createERC20(tokens[i]);
      groups.push({
        token: token,
        winners: winners[i] as number,
        amount: amounts[i] as BigNumber,
      });
    }
    return this._maybeUpdateData<RafflePrizeGroupdata>(update, { prizeGroups: groups });
  }
  /**
   * Queries the raffle for information about the win state of the current
   * logged account.
   * @return Promise [isWinner, WinGroup]
   */
  private async _getWinnerInfo(update = true): Promise<Pick<RaffleData, 'isWinner'|'wonGroup'>> {
    const [winner, group]: [boolean, BigNumber] = await this.contract.isWinner(this.api.account);
    return this._maybeUpdateData(update, {isWinner: winner, wonGroup: group.toNumber() });
  }
  /** Queries the contract to know if the current */
  private async _getHasClaimedPrize(update = true): Promise<boolean> {
    try {
    const claimed = await this.contract.hasClaimedPrize(this.account)
    this._maybeUpdateData(update, { hasClaimed: claimed })
    return claimed;
    } catch (error) {
      console.error(error)
      return false;
    }
  }
  /**
   * @internal function to ease setting up events.
   * @param event Event name to subscribe to
   * @param state matching state on the front end
   */
  private _subscribeToStateChange(event: string, state: RaffleState): void {
    this.contract.on(event, () => {
      this.data.status = state;
      this.api.updateRaffleState(this.address, state);
    });
  }
  /**
   * Admin guard to use when calling the contract.
   * @guard @internal
   * @returns true if the user is connected and an admin
   */
  private _ensureAdmin(): boolean {
    if (!this.api?.account || !this.accountIsAdmin) {
      utils.logger.notAdmin(this.api.account, `for raffle ${this.address}`);
      return false;
    }
    return true;
  }
  /**
   * State guard to prevent undesired calls to contract that we know will bounce
   * for contract specs
   * @guard @internal
   * @param states valid states to check against that will return true
   * @returns true if we're in one of the given states
   */
  private _ensureState(...states: RaffleState[]): boolean {
    if (!states.includes(this.data.status)) {
      return false;
    }
    return true;
  }

  private _updateData(data: Partial<RaffleData>): RaffleData {
    this.data = { ...this.data, ...data };
    return this.data;
  }
  private _maybeUpdateData<T extends Partial<RaffleData>>(update: boolean, data: T): T {
    if (update) this._updateData(data);
    return data;
  }
  /**
   * Safety check to ensure we are on the correct network.
   * This is used mainly in our fetch methods to ensure we don't try to
   * fetch data when the user changes network and our callbacks/intervals
   * are trying to use our set network values
   * TODO: this is a hotfix. what we should do is clear the intervals and readd them
   * when we detect the user changed network
   * @guard @internal
   * @returns true if the connected wallet is connected to the used provider
   *          network
   */
  private _ensureNetwork(): boolean {
    const connected = (window as any).ethereum?.networkVersion
    const expected = this.api.provider.network.chainId;
    const valid = Number(connected) === expected;
    if (!valid) utils.logger.error(`Connected to the wrong network: ${connected} instead of ${expected}`);
    return valid;
  }

  private _handleRefresh(): void {
    this._refreshes++;
  }
}

const validatePrizeGroups = (size: number, ...arrays: any[]): boolean => arrays.every((array) => array.length === size);

type RafflePrizeGroupdata = { prizeGroups: RafflePrizeGroup[] };
