import React, { createContext, useCallback, useState } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';

type PartialDialogProps = Omit<DialogProps, 'open'>;

interface DialogContext {
  content?: React.ReactNode;
  open?: boolean;
  onClose: () => void;
  onOpen: (content: React.ReactNode) => void;
  setProps: (props: PartialDialogProps) => void;
}

export const Context = createContext<DialogContext>({
  open: false,
  onOpen: () => null,
  onClose: () => null,
  setProps: (props: PartialDialogProps) => null,
});

const DIALOG_DEFAULTS: PartialDialogProps = { maxWidth: 'sm', fullWidth: true };

export const DialogContextProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<React.ReactNode>();
  const [props, setDialogProps] = useState<PartialDialogProps>(DIALOG_DEFAULTS);

  const setProps = useCallback((newProps) => setDialogProps({ ...DIALOG_DEFAULTS, ...newProps }), [setDialogProps]);

  const onOpen = useCallback(
    (dialogContent: React.ReactNode) => {
      setContent(dialogContent);
      setOpen(true);
    },
    [setContent, setOpen],
  );

  const onClose = useCallback(() => {
    setContent(undefined);
    setOpen(false);
  }, [setContent, setOpen]);

  return (
    <Context.Provider value={{ open, content, onOpen, onClose, setProps }}>
      {children}
      {open && (
        <Dialog {...props} open={open} onClose={onClose}>
          {React.isValidElement(content) && React.cloneElement(content, { onClose })}
        </Dialog>
      )}
    </Context.Provider>
  );
};

export default DialogContextProvider;
