/**
 * Mapping for the contracts errors
 * @dev these comes directly from the contract. For now, we just show the error
 * text in the contract docs. we'll have to come up with something more user friendly
 * and/or group them together for the output text.
 */

export type ContractError = {
  code: ERROR_CODE,
  error: string;
  message?: string
}

export type ERROR_CODE = keyof typeof _errors;

export type ContractErrors = {
  [code in ERROR_CODE]: ContractError;
}

// from contract source. error code => [error message, displayText?]
const _errors = {
  'e02': [
    "stakePoolAddress must be a valid address",
  ],
  'e03': [
    "contract calling not allowed",
  ],
  'e04': [
    "proxy contract not allowed",
  ],
  'e05': [
    "invalid time: start",
  ],
  'e06': [
    "invalid time: end",
  ],
  'e07': [
    "raffle not started",
  ],
  'e08': [
    "numberOfTickets should be > 0",
  ],
  'e09': [
    "raffle has invalid status to be started",
  ],
  'e10': [
    "raffle has invalid status to be ended",
  ],
  'e11': [
    "raffle has invalid status to be closed",
  ],
  'e12': [
    "raffle must be ended to draw",
  ],
  'e13': [
    "prizeDistributionGroupsSize must be > 0",
  ],
  'e14': [
    "size of prizeDistributionGroups must be the same as prizeDistributionGroupsSize",
  ],
  'e15': [
    "size of prizeDistributionTokens must be the same as prizeDistributionGroupsSize",
  ],
  'e16': [
    "costPerTicket must be > 0",
  ],
  'e17': [
    "timestamps are invalid",
  ],
  'e18': [
    "bankAddress must be a valid address",
  ],
  'e19': [
    "staking: user doesn't have that amount staked",
  ],
  'e20': [
    "user does not have staked tokes in the staking contract. purchase stopped",
    "You need to stake tokens to participate in the raffle."
  ],
  'e21': [
    "tokens are still locked",
  ],
  'e22': [
    "amountOfTime must be greater than 0",
  ],
  'e23': [
    "user does not have anything staked",
  ],
  'e24': [
    "raffle must be ended or closed",
  ],
  'e25': [
    "player not found in raffle",
  ],
  'e26': [
    "user is not a winner",
  ],
  'e27': [
    "size of prizePoolInUtilityToken must be the same as prizeDistributionGroupsSize",
  ],
  'e28': [
    "the tax recipient address should be valid",
  ],
  'e29': [
    "Not enough balance for this transfer",
  ],
  'e30': [
    "user already claimed prize",
  ],
  'e31': [
    "user does not have staked tokes in the staking contract. claim stopped",
  ],
  'e32': [
    "raffleParticipantRegistryAddress must be valid",
  ],
}

/** Builder function for consistency */
const makeError = (k: ERROR_CODE, displayText?: string): ContractError => ({
  code: k,
  error: _errors[k][0],
  message: displayText ?? _errors[k].length === 2 ? _errors[k][1] : _errors[k][0]
})

/** Usable errors list */
export const ERRORS: ContractErrors = Object.keys(_errors)
  .reduce((prev, k) => ({ ...prev, [k]: makeError(k as ERROR_CODE) }), {} as ContractErrors);

/**
 * Returns the user-friendly text for the given error code if it exists.
 * Falls back to the error text or to `Unknown Error` if error is unknown.
 */
export function _getDisplayText(code: ERROR_CODE) {
  return ERRORS[code]?.message ?? ERRORS[code]?.error ?? 'Unknown Error'
}

export function getErrorCode(error: { data: {message: string} }): ERROR_CODE {
  const msg: string = error?.data?.message ?? 'unknown: e00';
  return (msg.split(': ')[1]) as ERROR_CODE
}

export function getDisplayText(error: { data: {message: string} }): string {
  return _getDisplayText(getErrorCode(error))
}
/**
 * public API to get the error data directly from the thrown error
 * using the contracts errors list in the documentation
 */
 export function getError(error: { message?: string, data: {message: string} }) {
  const code = getErrorCode(error)
  return ERRORS[code] ?? { error: 'e00', message: error.data?.message ?? error.message }
}

/**
 * Default exports. Parsed and normalized list of errors and utility
 * to get the display text. for a given error
 */
export default {
  errors: ERRORS,
  getDisplayText,
  getErrorCode,
  getError,
};
