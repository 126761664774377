import { useCallback, useState } from 'react';

interface UseCappedSettings {
  min?: number;
  max?: number;
}

export type UseClampedReturnValue = [number, (v: number) => void, (v: number) => void]


const defaults = { min: 0, max: 1000 };

export const useClamped = (_settings: UseCappedSettings = defaults): UseClampedReturnValue => {
  const { min, max: _max } = { ...defaults, ..._settings };

  const [value, setValue] = useState(min);
  const [max, _setMax] = useState(_max);

  const setMax = useCallback((v: number) => _setMax(v), []);

  const setCapped = useCallback(
    (value: number) => {
      const v = Math.min(Math.max(value, min), max);
      setValue(v);
    },
    [max],
  );

  return [value, setCapped, setMax];
};

export default useClamped;
