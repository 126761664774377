import React, { createContext, useEffect, useState } from 'react';
import useTombFinance from '../../../hooks/useTombFinance';
import RaffleApi from '../api';
import { RaffleConfiguration } from '../types';

export interface RaffleApiContext {
  api: RaffleApi;
}

export const RaffleApiContext = createContext<RaffleApiContext>({ api: null });

export interface RaffleApiContextProvider {
  config: RaffleConfiguration;
}

export const RaffleApiContextProvider: React.FC<RaffleApiContextProvider> = ({ children, config }) => {
  const tombFinance = useTombFinance();
  const [api, setApi] = useState<RaffleApi>();

  useEffect(() => {
    if (tombFinance && !api) {
      const api = new RaffleApi(tombFinance, config);
      setApi(api);
    }
  }, [api, config, tombFinance]);

  return <RaffleApiContext.Provider value={{ api }}>{children}</RaffleApiContext.Provider>;
};

export default RaffleApiContextProvider;
