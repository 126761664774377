import React, { FC } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';

export interface RaffleButton {
  /** default text to show if approved is true */
  text: React.ReactChild;
  /** @optional text used for the approval mode */
  approveText?: React.ReactChild;
  /** @optional token name to show on the button */
  token?: string;
  /** should be true if the raffle utility token has been approved for the raffle */
  approved: boolean;
  /** @optional should be false if the user can't buy tickets (no stakes/raffle ended) */
  disabled?: boolean;
  /** approve function or buy ticket(s) (modal) */
  onApprove?: () => any;
  /** standard click callback. used if approve is true */
  onClick: () => any;
  /** button variant from material-ui */
  variant?: 'text' | 'outlined' | 'contained';
  /** colors variants from material-ui */
  color?: 'primary' | 'secondary' | 'default';
}

/**
 * Generic button for the raffles. Can manage approval functionality and other
 * default ones (buy/stake/unstake/...)
 */
export const RaffleButton: FC<RaffleButton> = ({
  disabled,
  approved,
  token,
  text,
  onClick,
  onApprove = () => null,
  approveText = 'Approve',
  variant = 'contained',
  color = 'default',
}) => {
  return (
    <Button
      fullWidth
      color={color}
      size="large"
      variant={variant}
      disabled={disabled}
      onClick={approved ? onClick : onApprove}
    >
      <Typography variant="button">
        {approved ? text : approveText} {token}
      </Typography>
    </Button>
  );
};

export default RaffleButton;
