import React from 'react'
import { Box, Container, Typography, Card, CardContent, CardActionArea, Button } from '@material-ui/core'


export default function RaffleBanner({}) {
  return (
    <Box mb={6}>
      <Container>
        <Card>
          <CardContent style={{paddingBottom: 16 }}>
            <Typography variant="h2" align="center">
              Win 1,000 USDC in our <strong>Raffle</strong>!
            </Typography>
          </CardContent>
          <CardActionArea>
            <Button href="/raffle" variant="contained" fullWidth>
              Go to the raffles
            </Button>
          </CardActionArea>
        </Card>
      </Container>
    </Box>
  )
}
