import { useContext, useState, useEffect, useCallback } from 'react';

import { Raffle } from '../api/Raffle';
import { RaffleApiContext } from '../context';
import { RaffleState } from '../enums';
import { RaffleConfiguration, RaffleData } from '../types';

interface UseRafflesSettings {
  refreshInterval?: number;
  onlyValid?: boolean;
}

/**
 * Single raffle hook. provided a raffle address, it will fetch the raffle data
 * and keep it updated every `refreshInterval` milliseconds.
 * @deprecated in favor of `useRaffles` to work in bulk
 * @param address raffle address
 * @param refreshInterval how often to refresh the raffle data
 * @returns individual raffle
 */
export const useRaffle = (address: string, refreshInterval = 500): Raffle => {
  const [raffle, setRaffle] = useState<Raffle>();
  const [, setData] = useState<RaffleData>(raffle?.data);
  const { api } = useContext(RaffleApiContext);

  const refresh = useCallback(() => {
    api?.getRaffle(address).then((raffle) => {
      setRaffle(raffle);
      setData(raffle.data);
    });
  }, [address, api]);

  useEffect(() => {
    refresh();
    /**
     * @dev hacky way to force the refresh of the hook to trigger
     * the re-render of the components.
     * Pools the data every tot time and if that changed the hook refreshes
     * allowing us to grab the data from the raffle.
     * If raffle.data didn't change this does nothing so it won't re-render.
     * TODO: find a better solution
     */
    const interval = setInterval(() => refresh(), refreshInterval);
    return () => clearInterval(interval);
  }, [setRaffle, api, refresh, refreshInterval]);

  return raffle;
};

/**
 *
 * @param poolInterval api config polling interval to sync up if not specified
 * @returns
 */
const DEFAULT_SETTINGS = (config: RaffleConfiguration): UseRafflesSettings => ({
  refreshInterval: config?.pollingInterval ?? 2500,
  onlyValid: true,
});

/**
 * Given a list of raffle states, queries the raffle api for all the raffles in
 * that state and returns them, refreshing them every `refreshInterval` ms.
 * @param states states we are interested in
 * @param refreshInterval how often the hook should refresh the raffles and trigger
 *  and trigger a componene re-render
 * @returns list of Raffle objects, valid and initalized
 */
export const useRaffles = (states: RaffleState[], _settings: UseRafflesSettings = {}): Raffle[] => {
  const { api } = useContext(RaffleApiContext);
  const [raffles, setRaffles] = useState<Raffle[]>([]);
  const [settings, setSettings] = useState<UseRafflesSettings>({...DEFAULT_SETTINGS(api?.config), ..._settings});

  const refresh = useCallback(() => {
    api?.getRaffles(...states).then((raffles) => {
      setSettings({...DEFAULT_SETTINGS(api?.config), ..._settings});
      // FIXME: this is required because some raffles may not have been set up
      // properly and some tokens might be null addresses, which breaks other
      // older hooks that we are reusing
      raffles = settings.onlyValid ? raffles.filter((r) => r.isValid) : raffles;
      raffles = raffles.sort((a, b) => a.data.status - b.data.status);
      setRaffles([...raffles]);
      // setRafflesData(raffles.map((raffle) => raffle.data));
    });
  }, [api, states, _settings.onlyValid]);

  useEffect(() => {
    refresh();
    const interval = setInterval(refresh, settings.refreshInterval);
    return () => clearInterval(interval);
  }, [api, states, refresh, settings.refreshInterval]);
  return raffles;
};
