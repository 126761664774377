import React from 'react';
import RaffleTable from '../components/admin/RaffleAdminTable';

export const RaffleAdmin = () => {
  return (
    <>
      <RaffleTable />
    </>
  );
};

export default RaffleAdmin;
