import React, { FC } from 'react';
import moment from 'moment';

import { ApprovalState } from '../../../../hooks/useApprove';
import RaffleButton from './Button.base';
import RaffleTimer from './RaffleTimer';

export interface UnstakeButton {
  lockEnd: moment.Moment;
  approval?: ApprovalState;
  onClick: () => void;
}
export const UnstakeButton: FC<UnstakeButton> = ({ onClick, lockEnd }) => {
  const now = moment();
  const disabled = !!lockEnd && !lockEnd.isBefore(now);
  // const approved = approval === ApprovalState.APPROVED;

  const Timer = () => (
    <RaffleTimer inline text="Withdraw in" start={now} end={lockEnd ?? now} timerSize="button" hideCaption={true} />
  );
  return (
    <RaffleButton
      text={disabled ? <Timer /> : 'Withdraw'}
      approved={true}
      variant="outlined"
      disabled={disabled}
      onClick={onClick}
    />
  );
};

export default UnstakeButton;
