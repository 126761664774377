import React, { FC, useState, useCallback, useEffect } from 'react';
import { Grid, Typography, useMediaQuery, Divider } from '@material-ui/core';
import type { GridSize } from '@material-ui/core/Grid';
import moment from 'moment';

import { Raffle } from '../../api';
import { RaffleState } from '../../enums';
import { RaffleDataJS } from '../../types';
import { useDialog } from '../../hooks/useDialog';
import utils from '../../utils';

import Wrapper from './Wrapper';
import RaffleTimer from './RaffleTimer';
import TicketPrice from './TicketPrice';
import OwnedStatus from './OwnedStatus';
import RaffleReward from './RaffleReward';

import BuyTicketDialog from './Dialog.buyTicket';
import StakeDialog from './Dialog.staking';
import StakeButton from './Button.stake';
import UnstakeButton from './Button.unstake';
import BuyButton from './Button.buy';
import ClaimButton from './Button.claim';
import { WinInfo } from './WinInfo';
import RaffleToolbar from '../Raffle.toolbar';
import ContractsButtons from '../ContractsButtons';

import { RaffleAdminToolbar } from '../admin/AdminToolbar';

import transactions from '../../hooks/useTransactions';
import { useUtilityBalance, useStakingBalance } from '../../hooks';

export interface RaffleItem {
  raffle: Raffle;
}

const isRunning = (raffle: Raffle) => raffle.data.status <= RaffleState.STARTED;
const isEnded = (raffle: Raffle) => raffle.data.status > RaffleState.STARTED;

const flexEnd = (md: boolean) => (md ? 'flex-end' : 'center');

/**
 * Main component for the raffle system.
 * Currently handles most of the logic, wraps all the subcomponents passing data,
 * and queries the raffle and its contracts for the data that's missing on the
 * Raffle object.
 * @dev this will be most likely refactored in a lot of ways either with a bunch
 * of hooks and/or moving business logic in and out of the Raffle/RaffleAPI objects
 * depending on how it will fit into the overall architecture.
 * Right now it's just a "it works, it's not perfect, but will do" thing.
 */
export const RaffleItem: FC<RaffleItem> = ({ raffle }) => {
  const isMdUp = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  const [data, setData] = useState<RaffleDataJS>(null);
  useEffect(() => {
    if (raffle && raffle.data) {
      setData(raffle.toJS());
    }
  }, [raffle, raffle.isReady, raffle?.refreshes]);

  // Staking management ///////////////////////////////////////////////////////
  const [stakeApproval, approveStaking] = transactions.useApproveStaking(raffle);
  const [staked, setStaked] = useState(1);
  const [lockEnd, setLockEnd] = useState<moment.Moment>(null);
  const [taxRate, setTaxRate] = useState(0);
  /** Update staking information
   * TODO: This data should be on the raffle data
   */
  useEffect(() => {
    if (!raffle.stakingContract || !raffle.stakingToken) return;

    raffle.getStakingValues().then(([amount, lockTime]) => {
      setStaked(raffle.utils.BnToNumber(amount, raffle.stakingToken.decimal));
      setLockEnd(moment.unix(lockTime.toNumber()));
      raffle.contract.taxRateOnPrize().then((rate: any) => setTaxRate(rate.toNumber()));
    });
  }, [raffle.stakingContract, raffle.stakingToken, raffle?.refreshes, stakeApproval]);

  // Buy ticket management ////////////////////////////////////////////////////

  const buyTicketsTransaction = transactions.useBuyTickets(raffle);
  const buyTickets = useCallback(
    (amount: number) => {
      buyTicketsTransaction(amount);
      onCloseBuyTickets();
    },
    [buyTicketsTransaction],
  );
  const [utilityApproval, approveUtility] = transactions.useApproveUtility(raffle);
  const utilityBalance = useUtilityBalance(raffle);
  const getTicketCost = useCallback((amnt: number) => raffle.getTicketsCost(amnt), [raffle]);
  const [openBuyTickets, onCloseBuyTickets] = useDialog(
    <BuyTicketDialog
      onClose={() => onCloseBuyTickets()}
      onPurchase={buyTickets}
      token={raffle.utilityToken}
      getCost={getTicketCost}
      balance={utilityBalance.balance}
    />,
  );
  // getTicketCost(1).then(c => console.info(`cost for 1 is ${c}`))
  // Staking modals ////////////////////////////////////////////////////////////

  const stakeTokensTransaction = transactions.useStake(raffle);
  const stakeTokens = useCallback(
    (amount: number) => {
      stakeTokensTransaction(amount);
      onCloseStaking();
    },
    [stakeTokensTransaction],
  );
  const stakeBalance = useStakingBalance(raffle);
  const [openStaking, onCloseStaking] = useDialog(
    <StakeDialog
      action="stake"
      staked={staked}
      maxCount={stakeBalance.balance}
      onConfirm={stakeTokens}
      onClose={() => onCloseStaking()}
      token={raffle.stakingToken}
    />,
  );
  const unstakeTokensTransaction = transactions.useUnstake(raffle);
  const unstakeTokens = useCallback(
    (amount: number) => {
      unstakeTokensTransaction(amount);
      onCloseUnstaking();
    },
    [unstakeTokensTransaction],
  );
  const [openUnstaking, onCloseUnstaking] = useDialog(
    <StakeDialog
      action="unstake"
      staked={staked}
      onConfirm={unstakeTokens}
      onClose={() => onCloseUnstaking()}
      token={raffle.stakingToken}
    />,
  );

  // Reward Groups and Claiming ////////////////////////////////////////////////
  const [rewardSize, setRewardSize] = useState<GridSize>(12);
  useEffect(() => setRewardSize(utils.getRewardsColumnSize(raffle.data)), [raffle?.refreshes]);
  const onClaim = transactions.useClaimPrize(raffle);

  /**
   * @dev this should not be needed with the Suspense component but
   * it's not working most likely due to how the data stuff is set up.
   */
  if (!data || !raffle.isReady) return null;

  const TopInfo = () => {
    if (isRunning(raffle)) {
      return (
        <>
          <OwnedStatus owned={data.ownedTickets} total={data.totalTickets} />
          <TicketPrice symbol={raffle.utilityToken.symbol} price={data.ticketCost} />
        </>
      );
    } else if (isEnded(raffle) && data.isWinner && data.hasClaimed) {
      return <Typography variant="subtitle1">You Already claimed your reward</Typography>;
    } else if (isEnded(raffle) && data.isWinner) {
      return <WinInfo groups={data.prizeGroups} tax={taxRate} wonGroup={data.wonGroup} />;
    } else if (isEnded(raffle) && !data.isWinner) {
      return <Typography variant="subtitle1">You did not win</Typography>;
    }
    return null;
  };
  const TopButton = () => {
    if (isRunning(raffle)) {
      return (
        <BuyButton
          state={data.state}
          canBuy={raffle.canBuyTickets}
          token={raffle.utilityToken.symbol}
          approved={utilityApproval}
          onClick={openBuyTickets}
          onApprove={approveUtility}
          disabled={utilityBalance.balance < data.ticketCost}
        />
      );
    } else if (isEnded(raffle) && data.isWinner) {
      return <ClaimButton onClaim={onClaim} hasClaimed={data.hasClaimed} />;
    }
    return null;
  };

  return (
    <Wrapper>
      <Grid container spacing={4}>
        {/* Admin toolbar for quick actions */}
        {raffle.accountIsAdmin && <RaffleAdminToolbar raffle={raffle} />}
        <RaffleToolbar>
          <Grid container>
            <Grid item xs={12} md={3} container justify={isMdUp ? 'flex-start' : 'center'}>
              <Typography variant="h6">Contracts</Typography>
            </Grid>
            <Grid item xs={12} md={9} container justify={flexEnd(isMdUp)}>
              <ContractsButtons raffle={raffle} />
            </Grid>
          </Grid>
        </RaffleToolbar>
        <Grid item xs={12} container alignItems="stretch" direction="column"></Grid>

        {/* Timer */}
        <Grid item xs={12} md={6} container justify="center" alignItems="center" direction="column">
          <RaffleTimer text="raffle" start={data.startsAt} end={data.endsAt} state={data.state} />
        </Grid>
        {/* <Divider orientation="vertical" flexItem /> */}
        {/* End timer - start UTILITY token management */}
        {/* Tickets info / Win Info */}
        <Grid item xs={12} md={3} container justify="center" alignItems={flexEnd(isMdUp)} direction="column">
          <TopInfo />
        </Grid>
        {/* Buy Button / Claim Button / Nothing */}
        <Grid item xs={12} md={3} container alignItems="stretch" justify={flexEnd(isMdUp)}>
          <TopButton />
        </Grid>
        {/* End utility - start STAKING */}

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>

        <Grid item xs={12} md={3} container justify="center" alignItems="center" direction="column">
          <StakedInfo amount={staked} token={raffle.stakingToken.symbol} />
        </Grid>

        <Grid item xs={12} md={3} container justify="center" alignItems="center" direction="column">
          {staked > 0 && (
            <>
              <Typography variant="h6">Tax Rate</Typography>
              <Typography variant="h4">{taxRate}%</Typography>
            </>
          )}
        </Grid>

        {/* primary action for staking. manages approval and staking modal */}
        <Grid item xs={12} md={staked ? 3 : 6} container justify="center" alignItems="flex-end" direction="column">
          <StakeButton
            onClick={openStaking}
            approval={stakeApproval}
            onApprove={approveStaking}
            symbol={raffle.stakingToken.symbol}
          />
        </Grid>
        {/* conditional unstake button, only shows if we have staked tokens. shows timer if in locking period */}
        {!!staked && (
          <Grid item xs={12} md={3} container justify="center" alignItems="flex-end" direction="column">
            <UnstakeButton onClick={openUnstaking} lockEnd={lockEnd} />
          </Grid>
        )}

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>

        {/* Prize Groups */}
        <Grid item xs={12} container justify="center" style={{ paddingTop: 16 }}>
          <Typography variant="h5">PRIZES</Typography>
        </Grid>
        <Grid item xs={12} container direction="row" alignItems="stretch" justify="space-evenly">
          {data.prizeGroups.map((group, i) => (
            <Grid key={i} item xs={12} sm={6} md={rewardSize} container>
              <RaffleReward
                group={group}
                taxRate={taxRate}
                hasTickets={data.ownedTickets > 0}
                isWinner={data.isWinner && data.wonGroup === i}
                onClaim={() => raffle.claimReward()}
                hasClaimed={data.hasClaimed}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default RaffleItem;

export interface StakedInfo {
  amount: number;
  token: string;
}
export const StakedInfo: FC<StakedInfo> = ({ amount, token }) => {
  const amnt = Number.isInteger(amount) ? amount : amount.toFixed(2);
  const isMdUp = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const align = isMdUp ? 'left' : 'center';
  return (
    <>
      <Typography align={align} variant="h6">
        Your deposit
      </Typography>
      <Typography align={align} variant="h5">
        {amnt} {token}
      </Typography>
    </>
  );
};
