import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
// import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { useSetRaffleState } from '../../hooks';
import { RaffleState } from '../../enums';
import type { Raffle } from '../../api';

import Toolbar from '../Raffle.toolbar';
import StateSelect from './Select.state';

export interface RaffleAdminToolbar {
  raffle: Raffle;
}

export const RaffleAdminToolbar: React.FC<RaffleAdminToolbar> = ({ raffle }) => {
  const [state, setState] = useState<RaffleState>(RaffleState.CREATED);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [ticketsBought, setTicketsBought] = useState(0);
  // useCallback with transaction hook
  const updateRaffleState = useSetRaffleState(raffle);

  useEffect(() => {
    if (!raffle) return;
    const dataJs = raffle.toJS();
    setIsAdmin(raffle.accountIsAdmin);
    setState(raffle.data.status);
    setTicketsBought(dataJs.totalTickets);
  }, [raffle.refreshes]);

  if (!isAdmin) return null;

  return (
    <Toolbar color="light">
      <Grid container spacing={2}>
        <Grid item xs={12} md={9} container alignItems="center" justify="flex-start" direction="row">
          <Grid item xs={12}>
            <Typography variant="h6">Admin controls</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Total tickets bought: {ticketsBought}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} container justify="center" alignItems="center">
          <StateSelect value={state} onSelect={updateRaffleState} />
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default RaffleAdminToolbar;
