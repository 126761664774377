import Web3 from 'web3';
import type { HttpProviderOptions, WebsocketProviderOptions } from 'web3-core-helpers';
import { RaffleState } from '../enums';
import { BigNumber, ethers } from 'ethers';
import moment, { Moment } from 'moment';
import { RaffleData, RaffleDataJS } from '../types';
import type { Raffle } from './Raffle';
import ERC20_ABI from './abi/erc20.abi.json'
import ERC20 from '../../../tomb-finance/ERC20';

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000'

export const makeERC20 = async (address:string, provider: ethers.providers.Provider|ethers.Signer): Promise<ERC20> => {
  // since we don't know the required parameters we first create the contract
  // then query it for symbol and decimals
  const c = new ethers.Contract(address, ERC20_ABI, provider)
  return new ERC20(address, provider, await c.symbol(), await c.decimals())
}

export const BnToNumber = (bn: ethers.BigNumber, decimals: number): number => {
  return bn.div(BigNumber.from(10).pow(decimals)).toNumber();
}

/**
 * Creates a dedicated Provider object for the given network for the raffle system
 * to use.
 * This should not be used in production as the API would default to the core API
 * provider already set up.
 * @param rpc RPC Url
 * @param config Optional configuration object for the providers
 * @returns Web3Provider object
 */
export const getWeb3Provider = (rpc: string, config: HttpProviderOptions | WebsocketProviderOptions = {}): any => {
  const Provider = rpc.startsWith('wss') ? Web3.providers.WebsocketProvider : Web3.providers.HttpProvider;
  return new Provider(rpc, config);
};
/**
 * Maps an incoming status value from a raffle contract into our enum values
 * for state for local purposes
 * @param status number or BigNumber status value from a contract
 * @returns enum value
 */
export const mapStatusToState = (status: number | BigNumber | RaffleState): RaffleState => {
  const _status = typeof status === 'number' ? status : status.toNumber();
  const knownStates = [RaffleState.CREATED, RaffleState.STARTED, RaffleState.ENDED, RaffleState.CLOSED];

  if (knownStates.includes(_status)) {
    return _status;
  }
  return RaffleState.UNKNOWN;
};
/**
 * Converts a number or a string to a byte32 representation to send over to
 * the contracts
 * @param value value to convert to byte32
 * @returns string of bytes32
 */
export const toByte32 = (value: string | number): string => {
  // const val = typeof value === 'number' ? value.toString() : value;
  const val = typeof value === 'string' ? Number(value) : value;
  return ethers.utils.formatBytes32String(val as any);
};

export const fromWei = (value: BigNumber): number => value.div(ethers.constants.WeiPerEther).toNumber();
export const toWei = (value: number): string => ethers.constants.WeiPerEther.mul(value).toString();

export const momentToBN = (timestamp: Moment): BigNumber => {
  return BigNumber.from(timestamp.unix());
};

export const isNullAddr = (addr: string | number): boolean => {
  return !addr || addr === NULL_ADDRESS || addr === 0;
};

const BN_ZERO = BigNumber.from(0);

export const getEmptyData = (): RaffleData => ({
  address: '',
  /** initial state defaults to UNKNOWN. will get set when the raffle data comes in */
  status: RaffleState.UNKNOWN,
  /** raffle start time in unix timestamp (BigNumber) */
  startTime: BN_ZERO,
  /** raffle end time in unix timestamp (BigNumber) */
  endTime: BN_ZERO,
  /** total amount of tickets bought for this raffle */
  totalBought: BN_ZERO,
  /** amount of tickets owned by the connected wallet */
  ownedTickets: BN_ZERO,
  /** amount of tokens burned when buying */
  burnAmount: BN_ZERO,
  /** total amount of tokens burned */
  totalBurnt: BN_ZERO,
  /** cost for one ticket */
  ticketCost: BN_ZERO,
  /** Address of the token to by ticket with */
  utilityToken: '',
  /** Address of the token used for the staking */
  stakingToken: '',
  stakedAmount: BN_ZERO,
  lockEnds: BN_ZERO,
  /** staking contract address */
  stakingContract: '',
  /** treasury contract address */
  treasuryContract: '',
  participantRegistry: '',
  taxRecipient: '',
  prizeGroups: [],
  /** true if the currently connected wallet is winner on this raffle */
  isWinner: false,
  /** true if the connected wallet is winner and has claimed the reward */
  hasClaimed: false,
  /** reward group the wallet won in */
  wonGroup: null,
});

export const raffleDataToJS = (data: RaffleData): RaffleDataJS => ({
  address: data.address,
  startsAt: moment.unix(data.startTime.toNumber()),
  endsAt: moment.unix(data.endTime.toNumber()),
  state: data.status,

  ownedTickets: data.ownedTickets.toNumber(),
  totalTickets: data.totalBought.toNumber(),
  ticketCost: fromWei(data.ticketCost),
  isWinner: data.isWinner,
  hasClaimed: data.hasClaimed,
  wonGroup: data.wonGroup,

  prizeGroups: (data.prizeGroups ?? []).map((group, i) => ({
    address: group.token.address,
    symbol: group.token.symbol,
    amount: fromWei(group.amount),
    winners: group.winners,
  })),
});

export namespace sorter {
  export const addressByState = (a: [string, RaffleState], b: [string, RaffleState]): number => {
    return a[1] === b[1] ? 0 : a[1] > b[1] ? -1 : 1;
  };
  export const raffleByState = (a: Raffle, b: Raffle): number => {
    return a.data.status === b.data.status ? 0 : a.data.status > b.data.status ? -1 : 1;
  };
}

export namespace logger {
  // log only if in development mode
  const SHOULD_LOG = process.env.NODE_ENV !== 'production';
  // const SHOULD_LOG = true;
  const prefix = ' [ Raffle ] ';
  const ICONS = {
    OK: '✅',
    ERR: '❌',
    WARN: '⚠️',
    DANGER: '🔥',
    PROVIDER: '🔌',
  };
  export const withIcon = (icon: string, message: string): string => `${icon}${prefix}${message}`;
  export const noIcon = (message: string): string => `${prefix}${message}`;

  export const log   = (...args: any[]) => SHOULD_LOG && console.log(...args);
  export const info  = (...args: any[]) => SHOULD_LOG && console.info(...args);
  export const error = (...args: any[]) => SHOULD_LOG && console.error(...args);
  export const warn  = (...args: any[]) => SHOULD_LOG && console.warn(...args);

  type method = 'log' | 'info' | 'error' | 'warn';
  export const generic = (text: string, icon: string = '', method: method = 'info') =>
    SHOULD_LOG && console[method](withIcon(icon, text));

  export const notAdmin = (account: string, text: string) => {
    warn(withIcon(ICONS.WARN, `${account} is not an admin ${text}`));
  }
  export const apiInit = () => {
    info(withIcon(ICONS.OK, `API initialized`));
  }
  export const providerSetup = () => {
    info(withIcon(ICONS.PROVIDER, `Setting up provider`));
  }
  export const customProvider = (rpc: string) => {
    info(withIcon(ICONS.PROVIDER, `Using custom provider for ${rpc}`));
  }
  export const raffleCreation = (raffle: Raffle) => {
    const icon = raffle.isValid ? ICONS.OK : ICONS.ERR;
    const text = raffle.isValid ? 'Raffle created' : 'Raffle creation failed';
    info(withIcon(icon, `${text}: ${raffle.address}`));
  };
  export const newToken = (token: string, symbol: string) => {
    info(withIcon(ICONS.WARN, `New Token added: ${symbol} (${token})`));
  }
  export const raffleNullAddress = (address: string) => {
    error(withIcon(ICONS.DANGER, `Factory returned an invalid raffle: ${address}`));
  }
  export const prizeGroupsInvalid = (raffleAddr: string, _error: string) => {
    error(withIcon(ICONS.DANGER, `Prize groups invalid for ${raffleAddr} | ${_error}`));
  }
  export const valueNotSet = (raffle: Raffle, value: string) => {
    error(withIcon(ICONS.ERR, `${value} not set on ${raffle.address}`));
  }
}

export default {
  NULL_ADDRESS,
  makeERC20,
  BnToNumber,
  getWeb3Provider,
  mapStatusToState,
  toByte32,
  fromWei,
  toWei,
  momentToBN,
  getEmptyData,
  raffleDataToJS,
  isNullAddr,
  sorter,
  logger,
};
