/**
 * Hooks to perform all public related transactions for the raffle system.
 * Module exports a single hooks to access all the internals with ease or
 * each individual hook as destructured export.
 *
 * @dev move all the utility types and functions into a separate module so that
 * they can be reused. ideally they should be along the hook that handles the
 * transaction recepits and notifications system, in some `core` module.
 */
import { useCallback } from "react";
import type { Raffle } from "../api";

import useApprove, { ApprovalState } from "../../../hooks/useApprove";

import { useTransaction } from "./common";

// Exported hooks /////////////////////////////////////////////////////////////

/** spending approval state and function for the staking token of the given raffle */
export const useApproveStaking = (raffle: Raffle): [ApprovalState, () => void] => {
  const [approval, approver] = useApprove(raffle.stakingToken, raffle.stakingContract.address);
  const approve = useCallback(() => approver(), [raffle, approver, raffle.refreshes])
  return [approval, approve]
}

/** spending approval stake and function for the utility token of the given raffle */
export const useApproveUtility = (raffle: Raffle): [ApprovalState, () => void] => {
  const [approval, approver] = useApprove(raffle.utilityToken, raffle.address);
  const approve = useCallback(() => approver(), [raffle, approver, raffle.refreshes])
  return [approval, approve]
}

/** @dev all the methods might need to be either binded or swapped to arrow functions */

/** Staking handler for the given raffle.
 * No checks are performed on the call, so either do them in the raffle method
 * or in the UI before calling.
 */
export const useStake = (raffle: Raffle) => {
  const { stakingToken, stakingContract } = raffle;
  return useCallback(useTransaction(raffle.stakeTokens.bind(raffle),
    (amount: number) => `Stake ${amount} ${stakingToken.symbol}`),
  [raffle, stakingToken, stakingContract]);
}
/** Unstaking handler for the given raffle.
 * No checks are performed on the call, so either do them in the raffle method
 * or in the UI before calling.
 */
export const useUnstake = (raffle: Raffle) => {
  const { stakingToken, stakingContract } = raffle;
  return useCallback(useTransaction(raffle.unstakeTokens.bind(raffle),
    (amount?: number) => `Unstake ${amount ?? 'all'} ${stakingToken.symbol}`),
  [raffle.unstakeTokens, stakingToken, stakingContract]);
}
/** Handler to buy tickets for the given raffle.
 * No checks performed in the hook; either do them before or in the buy method
 * of the raffle.
 */
export const useBuyTickets = (raffle: Raffle) => {
  return useCallback(useTransaction(raffle.buyTickets.bind(raffle),
    (amount: number) => `Buy ${amount} tickets`),
  [raffle.buyTickets, raffle.address]);
}
/** Handler to execute the claim reward on the given raffle */
export const useClaimPrize = (raffle: Raffle) => {
  return useCallback(useTransaction(raffle.claimReward.bind(raffle),
    () => `Claim prize`),
  [raffle.claimReward, raffle.address]);
}

export default {
  useApproveStaking,
  useApproveUtility,
  useStake,
  useUnstake,
  useBuyTickets,
  useClaimPrize,
}

/**
 * Takes a Raffle object and returns a series of transaction methods for the
 * end-user interface that hook up to the core website transaction handling and
 * notification/popup system.
 * @dev Ideally this wouldn't be integrated with the core system as we want
 * it to be as independent as possible, but for now it's needed due time constraints.
 * @param raffle Raffle to be used while building the transactions callbacks
 */
// const useClientTransactions = (raffle: Raffle) => {
//   const [utilityApproval, approveUtility] = useApproveUtility(raffle);
//   const [stakeApproval, approveStake] = useApproveStaking(raffle);

//   return {
//     // approve functions
//     approveUtility,
//     approveStake,

//     utilityApproval,
//     stakeApproval,
//     // raffle public transaction methods callbacks
//     stake: useStake(raffle),
//     unstake: useUnstake(raffle),
//     buyTickets: useBuyTickets(raffle),
//     claimPrize: useClaimPrize(raffle),
//   }
// }

// export default useClientTransactions;
