import React, { FC, useState, useCallback, useEffect } from 'react';
import { DialogActions, DialogContent, DialogTitle, DialogContentText, TextField, Button } from '@material-ui/core';
import { useClamped } from '../../hooks/useClamped';
import ERC20 from '../../../../tomb-finance/ERC20';

export interface BuyTicketDialog {
  onClose: () => void;
  onPurchase: (amount: number) => any;
  // maxAmount?: number;
  token: ERC20;
  balance: number;
  getCost: (amount: number) => Promise<number>;
}

export const BuyTicketDialog: FC<BuyTicketDialog> = (props) => {
  const { onClose, onPurchase, getCost, token, balance } = props;

  const [costOfOne, setCostOfOne] = useState(1);
  useEffect(() => {
    getCost(1).then((cost) => setCostOfOne(cost));
  }, [token, getCost]);

  const [maxAllowed, setMaxAllowed] = useState(1);

  const [price, setPrice] = useState<number>(0);
  const [selected, setSelected, setMaxClamp] = useClamped({ min: 1, max: maxAllowed });

  useEffect(() => {
    const canBuy = Math.floor(balance / (costOfOne || 1));
    setMaxAllowed(canBuy);
    setMaxClamp(canBuy);
    if (canBuy < selected) setSelected(canBuy);
  }, [balance, costOfOne]);

  const handleConfirm = useCallback(() => selected > 0 && onPurchase(selected), [onPurchase, selected]);

  useEffect(() => {
    getCost(selected).then(setPrice);
  }, [getCost, selected]);

  return (
    <>
      <DialogTitle>Buy Tickets with {token.symbol}</DialogTitle>
      <DialogContent>
        <DialogContentText variant="body2" align="center">
          You have{' '}
          <strong>
            {balance} {token.symbol}
          </strong>
          . You can buy up to <strong>{maxAllowed}</strong> tickets.
        </DialogContentText>
        <TextField
          fullWidth
          autoFocus
          type="number"
          value={selected}
          color="secondary"
          variant="outlined"
          label="Ticket amount"
          onChange={(e) => setSelected(parseInt(e.target.value, 10))}
        />
        <DialogContentText variant="body1" align="center" style={{ marginTop: '1rem' }}>
          Total cost: {price} {token.symbol}
        </DialogContentText>
        {/* SHOW BUY ERROR IF NEEDED */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => setSelected(maxAllowed)}
          disabled={balance < costOfOne}
          variant="contained"
          color="secondary"
        >
          MAX
        </Button>
        <Button onClick={handleConfirm} disabled={selected < 1 || balance === 0} variant="contained" color="secondary">
          BUY
        </Button>
      </DialogActions>
    </>
  );
};

export default BuyTicketDialog;
