import React, {FC} from "react";
import Card from "@material-ui/core/Card";

/**
 * Wrapper component for the Raffle items in the UI.
 * set as separate component so we can change it later if needed.
 */
export const Wrapper: FC = ({ children }) => (
  <Card style={{ width: '100%', padding: 16 }}>
    {children}
  </Card>
)

export default Wrapper;
