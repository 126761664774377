import moment from 'moment';
import type { GridSize } from '@material-ui/core/Grid';
import { RaffleState } from './enums';
import { RaffleData } from './types';

/**
 * functional utility module for the raffle page.
 *
 * TODO: Move into src/utils when done and refactor imports
 */

/**
 * Get the current tax rate reduced by the amount of staked shares
 * @param staked amount of tokens staked
 * @param maxTax maximum tax (0 staked) percentage in decimals
 * @param redTax reduction amount for each staked token - percentage in decimals
 * @returns new calculated tax rate
 */
export function calcTaxDiscount(staked: number, maxTax = 0.3, redTax = 0.025) {
  return (maxTax - staked * redTax) * 100;
}

/**
 * Get the unformatted countdown timer values to print
 * @param _now current time Moment object
 * @param _then future time Moment object
 * @returns [hh, mm, ss] strings - padded deltas between the two moment objects
 */
export function getTimeDelta(_now: moment.Moment, _then: moment.Moment): string[] {
  let h,
    m,
    s = 0;

  const ensureAboveZero = (n: number) => (n <= 0 ? 0 : n);
  const doubleDigit = (n: number): string => String(n).padStart(2, '0');

  h = ensureAboveZero(_then.diff(_now, 'hours'));
  m = ensureAboveZero(_then.diff(_now.hours(_now.hours() + h), 'minutes'));
  s = ensureAboveZero(_then.diff(_now.minutes(_now.minutes() + m), 'seconds'));

  return [doubleDigit(h), doubleDigit(m), doubleDigit(s)];
}

/**
 * Calculate the odds to win the raffle given the owned and total amount of tickets
 * @param owned Amount of ticket owned by the wallet
 * @param total total amount of tickets
 * @param decimal whether the result should be in decimal or percentage format
 * @returns odds of winning
 */
export function calcRaffleOdds(owned: number, total: number, decimal: boolean = true): number {
  if (!owned) return 0;
  return (owned / total) * (decimal ? 1 : 100);
}

/**
 * Calculate the width for the rewards grid component based on how many groups
 * the raffle has. Value is clamped between min and max provided, representing
 * the width of the column out of 12 max.
 */
export const getRewardsColumnSize = (data: RaffleData, min = 4, max = 6): GridSize => {
  if (!data) return 12;
  const width = Math.floor(12 / data.prizeGroups.length ?? 12);
  return Math.min(Math.max(width, min), max) as GridSize;
};

export namespace raffle {
  export const ended = (state: RaffleState) => state >= RaffleState.ENDED;
  export const started = (state: RaffleState) => state === RaffleState.STARTED;
}

export namespace text {
  export const getWinnerText = (winners: number) => (winners === 1 ? '1 Winner' : `${winners} Winners, each`);

  export const getTimerText = (state: RaffleState) =>
    raffle.ended(state) ? 'Raffle ended' : raffle.started(state) ? 'Raffle ends in' : 'Raffle starts in';
  export const ticketsText = (amount: number) =>
    amount === 0 ? "You don't have any ticket" : amount === 1 ? `You have 1 ticket` : `You have ${amount} Tickets`;
}

export default {
  calcTaxDiscount,
  calcRaffleOdds,
  getRewardsColumnSize,
  getTimeDelta,
  text,
  raffle,
};
