import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';

import { RaffleGroup } from '../../types';

export interface WinInfo {
  groups: RaffleGroup[];
  tax: number;
  wonGroup: number;
}

export const WinInfo: FC<WinInfo> = ({ groups, tax, wonGroup }) => {
  const { amount, symbol } = groups[wonGroup];
  const taxedAmount = amount - amount * (tax / 100);

  return (
    <>
      <Typography variant="subtitle1">You won!</Typography>
      <Typography variant="h4" align="center">
        <strong>{taxedAmount}</strong> {symbol}
      </Typography>
    </>
  );
};
