import React, { FC } from 'react';
import { DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';

export interface ConfirmationDialog {
  title: string;
  children: React.ReactNode;
  onConfirm: (...args: any[]) => any;
  onClose?: () => void;
  confirmText?: string;
  cancelText?: string;
}

export const ConfirmationDialog: FC<ConfirmationDialog> = ({
  title,
  children,
  onConfirm,
  onClose = () => null,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
}) => {
  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {cancelText}
        </Button>
        <Button variant="contained" color="secondary" onClick={onConfirm}>
          {confirmText}
        </Button>
      </DialogActions>
    </>
  );
};

export default ConfirmationDialog;
