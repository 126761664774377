// import
import { useCallback, useContext } from 'react';
import { Context } from '../context/Dialog.context';
import { DialogProps } from '@material-ui/core';

export const useDialog = (content: React.ReactNode, props: Omit<DialogProps, 'open'> = {}) => {
  const { onOpen, onClose, setProps } = useContext(Context);
  const handleOpen = useCallback(() => {
    setProps(props);
    onOpen(content);
  }, [onOpen, content, props, setProps]);
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return [handleOpen, handleClose];
};

export default useDialog;
