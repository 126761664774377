import React from 'react';
import { useWallet } from 'use-wallet';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Page from '../../components/Page';
import BackgroundImage from '../../components/BackgroundImage';
import UnlockWallet from '../../components/UnlockWallet';

import { RaffleApiContextProvider } from './context';
import { RaffleHome, RaffleAdmin } from './pages';

import { Link } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import useRaffleAdmin from './hooks/useRaffleAdmin';
import DialogContextProvider from './context/Dialog.context';

// TODO: the background image should be directly in the Page component and forget
// since it's in every page. it could be set up as a prop with default to allow
// to change it if/when needed
const Wrapper: React.FC<RaffleApiContextProvider> = ({ children, config }) => {
  const { account } = useWallet();
  return (
    <Page>
      <BackgroundImage />
      <RaffleApiContextProvider config={config}>
        <DialogContextProvider>{account ? children : <UnlockWallet />}</DialogContextProvider>
      </RaffleApiContextProvider>
    </Page>
  );
};

const Navigation: React.FC = () => {
  return (
    <Box>
      {/* <Button variant="contained" color="primary" onClick={changeAdmin}>Test AdminThingy</Button> */}
      <Link to="/raffle" component={Button}>
        Go to public page
      </Link>
      <Link to="/raffle/admin" component={Button}>
        Go to admin
      </Link>
    </Box>
  );
};

const RafflePage: React.FC<RaffleApiContextProvider> = ({ config }) => {
  const isAdmin = useRaffleAdmin();
  const { path } = useRouteMatch();

  console.info(`RafflePage ~ allowed? ${isAdmin}`);

  return (
    <Wrapper config={config}>
      {isAdmin && <Navigation />}
      <Switch>
        <Route exact path={path} component={RaffleHome} />
        {isAdmin && <Route path={`${path}/admin`} component={RaffleAdmin} />}
        <Route path="*">
          <p style={{ color: 'white', margin: '100px 0', textAlign: 'center' }}>
            Not Found.{' '}
            <Link to={path} style={{ color: '#ffd54f' }}>
              Go to Raffle Page.
            </Link>
          </p>
        </Route>
      </Switch>
    </Wrapper>
  );
};

export default RafflePage;
