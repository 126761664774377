import React from 'react'
import { Grid, Typography, Card as MuiCard, CardContent } from '@material-ui/core'
import Notice from '../../../components/Notice';
import { withStyles } from '@material-ui/styles';

const Card = withStyles({
  root: {
    backgroundColor: '#212121!important',
  }
})(MuiCard);

export const InstructionBox: React.FC = () => {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              How to play
            </Typography>
            <Typography variant="h5" align="center">
              Readme first
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Notice showIcon={false}>
              <Typography>1. Click <strong>APPROVE 2SHARES</strong></Typography>
              <Typography>2. <strong>DEPOSIT</strong> a minimum of 1 x 2SHARES in order to play. Each 2SHARES deposited reduces taxes by 2%. Tax cannot go lower than 10%</Typography>
              <Typography variant="subtitle1">(Note: Deposited tokens will be locked until end of raffle. If no tickets were purchased, you may withdraw after 24 hours of your deposit.) </Typography>
              <Typography>3. Click <strong>APPROVE 2OMB</strong></Typography>
              <Typography>4. <strong>BUY</strong> your Tickets. 1 Ticket = 100 x 2OMB</Typography>
              <Typography>5. Once the Raffle ends, please <strong>CLAIM</strong> any prizes you won and <strong>WITHDRAW</strong> your 2shares</Typography>
            </Notice>
          </Grid>

          <Grid item xs={12}>
            <Notice color='yellow'>
            This is currently a Beta Test. If there are any issues distributing the winnings properly and automatically, we will distribute the prize manually.
            </Notice>
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  )
}

export default InstructionBox;
