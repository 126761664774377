import { useCallback } from "react";

import useHandleTransactionReceipt, { ErrorParser } from "../../../hooks/useHandleTransactionReceipt";
import { TransactionResponse } from "@ethersproject/providers";

import { errors } from '../api'

/** Any async function that takes anything and returns a transaction response */
export type TnxFunction =  (...args: any[]) => Promise<TransactionResponse>;
/** shortcut for the transaction handler */
export type TransactionHandler = ReturnType<typeof useHandleTransactionReceipt>;
/**
 * @internal type for Message formatting functions.
 * paired with `TnxFunction` in the helper functions, accepts the same parameters
 * as the `TnxFunction` and returns a string to be displayed in the UI.
 * */
export type MessageFormatter<T extends TnxFunction>  = (...args: Parameters<T>) => string;

/** Helper function to programmatically build handled transactions */
const _createTransaction = <T extends TnxFunction>(handler: TransactionHandler, method: T, texter: MessageFormatter<T>, errorParser?: ErrorParser) => {
  return (...args: Parameters<typeof method>) => handler(method(...args), texter(...args), errorParser);
}

/** Given a transaction handler returns a closure for the createTransaction function */
const getCreateTransaction = (handler: TransactionHandler) => <T extends TnxFunction>(fn: T, formatter: MessageFormatter<T>, errorParser?: ErrorParser) =>
  _createTransaction(handler, fn, formatter, errorParser);


/**
 * Generic transaction handler that takes any transaction function and a formatting function
 * and returns a callback that handles and notifies the UI on transaction executions.
 * @dev uses website internal `useHandleTransactionReceipt` hook to handle transaction
 *      and the notifications. This should be changed in the future to a more
 *      generic solution that allows different dispatchers and such.
 */
export const useTransaction = <T extends TnxFunction>(fn: T, messager: MessageFormatter<T>) => {
  const handler = useHandleTransactionReceipt();
  const transaction = _createTransaction(handler, fn, messager, errors.getDisplayText);
  return useCallback(transaction, [transaction, fn, messager]);
}
