import type TombFinance from '../../../tomb-finance';
import type ERC20 from '../../../tomb-finance/ERC20';

import { BigNumber, Contract, ethers } from 'ethers';
import moment from 'moment';

import utils from './utils';
import errors from './errors'
import type { RaffleConfiguration } from '../types';
import { RaffleState } from '../enums';
import { Raffle } from './Raffle';
import TEST_RAFFLES_ADDRESS_TO_FILTER from './filtered_raffles'

export class RaffleApi {
  public config: RaffleConfiguration;
  private _provider: ethers.providers.Web3Provider;
  // local state to know if the connected wallet is considered an admin
  private _isAdmin: boolean = false;
  // Factory contract for all the raffles;
  private _factoryContract: Contract;
  // Staking contract that manages the stake and taxes
  private _stakingContracts: Contract[] = [];
  // Treasury contract to handle rewards
  private _treasuryContracts: Contract[] = [];
  private _tokens: {[addr: string]: ERC20} = {};
  /** list of all the known raffles, their address and their state */
  public raffleAddresses: {[key: string]: RaffleState} = {};
  /** concrete raffles objects with data and contract functionalities */
  public raffles : Raffle[] = [];

  public utils: typeof utils;

  constructor(public core: TombFinance, config: RaffleConfiguration) {
    // ugly as hell. will do it better later.
    this.config = config;
    this.utils = utils;
    this.setupProvider();

    this.core.subscribeToUnlock(this._onWalletUnlocked.bind(this));
    this.setIsAdmin()

    const { address, abi } = config;
    this._factoryContract = new Contract(address.factory, abi.factory, this.provider);
    this.initialize();

    // (window as any).r = this;
    // (window as any).w = RaffleState;
    // (window as any).b = BigNumber;
    // (window as any).u = utils;
    // (window as any).m = moment;
    // (window as any).k = errors;
    // (window as any).R = Raffle;
  }

  public get contract(): Contract {
    return this._factoryContract;
  }

  public getStakingContract(address: string): Contract {
    return this._getOrCreateContract(this._stakingContracts, address, this.config.abi.staking)
  }

  public getTreasuryContract(address: string): Contract {
    return this._getOrCreateContract(this._treasuryContracts, address, this.config.abi.treasury)
  }

  public getToken(address: string): ERC20 {
    return this._tokens[address];
    // return this._tokens.find(c => c.address === address);
  }

  public get provider(): ethers.providers.Web3Provider {
    return this._provider;
  }
  public get signer(): ethers.Signer {
    // FIXME: we should use this.provider.getSigner() but it breaks
    return this.core?.signer
  }
  public get account(): string {
    return this.core?.myAccount;
  }

  public get isAdmin(): boolean {
    return this._isAdmin;
  }

  /**
   * @returns list of all the raffles fetched from the factory contract
   */
  public getAllRaffleAddresses(): Promise<string[]> {
    return this.getRaffleAddresses()
  }

  /**
   * @returns list of raffle addresses for the public views
   */
  public getPublicRaffleAddresses(): Promise<string[]> {
    return this.getRaffleAddresses(
      RaffleState.STARTED, RaffleState.CLOSED, RaffleState.ENDED);
  }

  public getRaffleAddresses(...states: RaffleState[]): Promise<string[]> {
    const filtered = Object.entries(this.raffleAddresses)
    // filter by states requested. if empty return all
    .filter(([, state]) => states.length ? states.includes(state) : true)
    // sort by state, 'higher' states first
    .sort(utils.sorter.addressByState)
    // get only the addresses
    .map(([address,]) => address)

    return Promise.resolve(filtered);
  }

  public async getRaffles(...states: RaffleState[]): Promise<Raffle[]> {
    const addresses = await this.getRaffleAddresses(...states);
    return Promise.all(addresses.map(a => this.getRaffle(a)));
  }

  /**
   * Tried to retrieve a raffle from local storage.
   * If that raffle is missing or outdated, it will be fetched created anew,
   * added to the storage and returned.
   * @param address address of the raffle to get
   * @returns Raffle instance
   */
  public getRaffle(address: string): Promise<Raffle> {
    const _raffle = this.raffles.find(r => r.address === address);
    if (_raffle) return Promise.resolve(_raffle);
    return this.setupRaffle(address);
  }
  /**
   * Queries the factory contract for the number of deployed raffles that
   * it manages.
   * @returns number of raffles from the current factory
   */
  public async getFactoryRafflesCount(): Promise<number> {
    const _factory = this._factoryContract;
    const _count: BigNumber = await _factory.getCountOfDeployedRaffles();
    return _count.toNumber();
  }
  /**
   * Fetches ALL the raffle addresses from the factory contract. If we already
   * had pulled the public raffles these gets updated.
   * @contract @admin
   */
  public async updateAllRaffleAddresses(): Promise<void> {
    this.raffleAddresses = {
      ...this.raffleAddresses,
      ...await this._fetchRafflesAddresses(
        RaffleState.CREATED, RaffleState.STARTED,
        RaffleState.CLOSED, RaffleState.ENDED)
    }
  }
  /**
   * Updates the raffle addresses object with all the public raffles that the
   * factory deployed and their current state.
   */
  public async updatePublicRaffleAddresses(): Promise<void> {
    this.raffleAddresses = {
      ...this.raffleAddresses,
      ...await this._fetchRafflesAddresses(RaffleState.STARTED,RaffleState.CLOSED,RaffleState.ENDED)
    }
  }
  /**
   * Utility function to allow Raffle objects to update global registry
   * with their state when they are updated. This allows to keep track of
   * their state in the UI for the end users.
   * @internal
   */
  public updateRaffleState(address: string, state: RaffleState): void {
    this.raffleAddresses = {
      ...this.raffleAddresses,
      [address]: state
    }
  }
  // TODO: Implement methods to create raffles (check for isAdmin)
  // TODO: Implement methods to UPDATE raffles (check for isAdmin)

  /////////////////////////////////////////////////////////////////////////////
  // Contract events callbacks

  /**
   * Create a new Raffle instance and ensure it is added to the list
   * of known raffles.
   */
  public async setupRaffle(address: string): Promise<Raffle> {
    const found = this.raffles.find(r => r.address === address);
    if (found) return found;

    const raffle = await this._createRaffle(address)
    await this.createERC20(raffle.data.utilityToken);
    await this.createERC20(raffle.data.stakingToken);
    // this should not be needed but is to ensure the tokens already
    // exist
    for (const group of raffle.data.prizeGroups) {
      await this.createERC20(group.token.address);
    }

    utils.logger.raffleCreation(raffle)
    return raffle;
  }

  public async createERC20(address: string): Promise<ERC20> {
    const [token] = await this._createERC20(address);
    return token;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Private internal methods

  /**
   * Query the factory for the given raffle state and return an object with
   * the addresses and the state of those raffles.
   * @param states state to fetch raffles for
   * @returns obj with {address: state}
   */
   private async _fetchRafflesAddresses(...states: (RaffleState)[]): Promise<{[k: string]: RaffleState}> {
    const out: {[k:string]: RaffleState} = {};
    for (const state of states) {
      for (const address of await this.contract.getAllRafflesByStatus(state)) {
        if (utils.isNullAddr(address)) {
          utils.logger.raffleNullAddress(address)
          continue;
        };
        if (TEST_RAFFLES_ADDRESS_TO_FILTER.includes(address)) {
          continue;
        }
        out[address] = state;
      }
    }
    return out;
  }

  /** Setup the provider object(s) to use.
   * This will either come from the core API or set up a new provider object
   * depending on the provided configuration.
   * @dev right now we're hooking up web3 with ethers in a single provider.
   *      this will change in the future, switching to ethers only with provider
   *      and signer objects.
   */
  private setupProvider() {
    utils.logger.providerSetup()
    const { provider } = this.config
    // if config.provider data is missing use the defaults from core api
    if (!provider?.rpc || !this.config.testnet) {
      this._provider = this.core.provider;
      return;
    }
    // Config has the same provider as the core API. using that one.
    if (provider.rpc === this.core.provider.connection.url) {
      this._provider = this.core.provider;
      return;
    }
    utils.logger.customProvider(provider.rpc)
    // TODO: Refactor to use only ethers. right now this takes from web3
    this._provider = new ethers.providers.Web3Provider(
      utils.getWeb3Provider(provider.rpc, provider.options),
      provider.chainId,
    );
  }

  /**
   * @dev handle subscription to contract events and initialize the local state.
   *      when called raffleAddresses gets reset to a new array.
   */
  private async initialize(): Promise<void> {
    await this.updateAllRaffleAddresses()
    utils.logger.apiInit()
    utils.logger.generic(`Raffles will update every ${this.config.pollingInterval}ms`, '🔥')
  }

  /** @internal */
  private _onWalletUnlocked(): void {
    this.setIsAdmin();
  }

  /**
   * Check on local state and query the factory contract to know if the current
   * user is actually an admin.
   * @internal
   */
  private async setIsAdmin(): Promise<void> {
    this._isAdmin =
      this.account
      && this.contract
      && await this.contract.hasRole(
        utils.toByte32(this.config.adminRole),
        this.core.myAccount)
  }

  /**
   * Internal lookup function to search for staking/factory/... contracts and
   * create the contract objects if missing.
   * @internal
   * @param container Contract[] to search into
   * @param address address of the contract to look up
   * @param abi ABi to use in case we need to create a new contract
   * @returns Search contract
   */
  private _getOrCreateContract(container: Contract[], address: string, abi: any[]): Contract {
    const found = container.find(c => c.address === address);
    if (found) return found;
    utils.logger.generic(`Creating contract for ${address}`, '⚠️');
    const contract = new Contract(address, abi, this.signer);
    container.push(contract);
    return contract;
  }

  private async _createRaffle(address: string): Promise<Raffle> {
    const raffle = new Raffle(address, this)
    // initialize and await for the raffle to fetch all its data
    await raffle.initialize();
    // resort all raffles with current one in the right spot
    this.raffles = [...this.raffles, raffle].sort(utils.sorter.raffleByState);
    if (!(address in this.raffleAddresses)) {
      this.raffleAddresses = {
        ...this.raffleAddresses,
        [address]: raffle.data.status ?? -1
      }
    }
    return raffle
  }

  /**
   * Attempts to create and add a new ERC20 token. If the token already exists
   * it will be returned. if not it will be created and added to the list.
   * If provided address is a null address it will be ignored.
   * @param address erc20 token address
   * @returns {[boolean, ERC20]} boolean will be true if created, ERC20 not null
   *  if created or already existing.
   */
  private async _createERC20(address: string): Promise<[ERC20, boolean]> {
    if (utils.isNullAddr(address)) {
      return [null, false];
    }

    const utilityToken = this._tokens[address]
    if (utilityToken != undefined) {
      return [utilityToken, false]
    }
    this._tokens[address] = null;
    // create the ERC20 tokens used by the raffle if they are missing
    const token = await utils.makeERC20(address, this.signer);
    utils.logger.newToken(token.address, token.symbol)
    this._tokens[address] = token;
    return [token, true]
  }

}

export default RaffleApi;
