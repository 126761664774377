import { useState, useContext, useEffect } from 'react';
import { RaffleApiContext } from '../context';

export const useRaffleAdmin = () => {
  const { api } = useContext(RaffleApiContext);
  const [admin, setAdmin] = useState<boolean>(false);

  useEffect(() => {
    api && setAdmin(api.isAdmin);
  }, [api, api?.isAdmin]);

  return admin;
};
export default useRaffleAdmin;
