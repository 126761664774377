import React from 'react'
import { Raffle } from '../api';
import AddressButton from './AddressButton'

export interface ContractsButtons {
  raffle: Raffle;
}

export const ContractsButtons: React.FC<ContractsButtons> = ({ raffle }) => (
  <>
    <AddressButton name="Raffle" address={raffle.address} />
    <AddressButton name="Treasury" address={raffle.treasuryContract.address} />
    <AddressButton name="Staking" address={raffle.stakingContract.address} />
  </>
)

export default ContractsButtons;
