
/** Current state of the raffle.
 * Comes from the contract state on request (should update on start/end times)
 * and should be updated to match contract raffle states if they exist.
 */
 export enum RaffleState {
  /** Raffle exists but it's not started yet, i.e. start time is in the future */
  CREATED,
  /** Raffle is open and counting down */
  STARTED,
  /** Raffle ended. backend still processing winners and such */
  ENDED,
  /** Raffle is closed either because the timer expired or the auth wallets said so */
  CLOSED,
  /** Unknown state. should never happen */
  UNKNOWN = -1,
}
