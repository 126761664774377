import React, { FC, useState, useEffect } from 'react';
import { PropTypes, Typography } from '@material-ui/core';

import utils from '../../utils';

export interface OwnedStatus {
  owned: number;
  total: number;
  align?: PropTypes.Alignment;
}
export const OwnedStatus: FC<OwnedStatus> = ({ owned, total, align = 'right' }) => {
  const [odds, setOdds] = useState('0%');
  useEffect(() => {
    const _odds = utils.calcRaffleOdds(owned, total, false);
    if (_odds < 1) {
      return setOdds('less than 1%');
    }
    setOdds(`${_odds.toFixed(2)}%`);
  }, [owned, total]);

  return (
    <>
      <Typography align={align} style={{ padding: 4 }}>
        {utils.text.ticketsText(owned)}
      </Typography>
      {!!owned && <Typography align={align}>Your odds are {odds}</Typography>}
    </>
  );
};

export default OwnedStatus;
