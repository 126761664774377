import type { Variant as TypoVariant } from "@material-ui/core/styles/createTypography"
import React, { FC, useState, useEffect } from 'react'

import { Typography, PropTypes } from '@material-ui/core';

import { Raffle } from '../../api';


export interface TicketPrice {
  price: number;
  symbol: string;
  variant?: TypoVariant;
  align?: PropTypes.Alignment;
}
export const TicketPrice : FC<TicketPrice> = ({ symbol, price, variant = 'body1', align='right'}) => {
  return (
    <Typography align={align} variant={variant}>
      one ticket for <strong>{price} {symbol}</strong>
    </Typography>
  )
}

export default TicketPrice;
