import React from 'react';
import Button from '@material-ui/core/Button';

const makeFtmScanUrl = (address: string) => `https://ftmscan.com/address/${address}`

export interface AddressButton {
  address: string;
  name: string;
}
/** Quick and dirty component to link the various raffle contracts to their ftmscan page */
export const AddressButton: React.FC<AddressButton> = ({ address, name }) => (
  <Button href={makeFtmScanUrl(address)} rel="noopener noreferrer" target="_blank" style={{marginLeft: '1em'}}>
    {name}
  </Button>
)

export default AddressButton;
