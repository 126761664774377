/**
 * Module contains administrative transactions to create/modify raffles.
 * These are separated from the `useTransactions` hooks for organization
 * purposes but they may be moved together (or split individually) later
 */

import { Raffle } from "../api";
import { useTransaction } from "./common";

export const useSetRaffleState = (raffle: Raffle) => {
  return useTransaction(raffle.setState.bind(raffle),
    (state: number) => `set state to ${state} on ${raffle.address} `);
}
