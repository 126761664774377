import React, { FC } from 'react';
import { ApprovalState } from '../../../../hooks/useApprove';
import { RaffleState } from '../../enums';
import RaffleButton from './Button.base';

export interface BuyButton {
  state: RaffleState;
  canBuy: boolean;
  token: string;
  approved: ApprovalState;
  onClick: () => void;
  onApprove: () => void;
  disabled?: boolean;
}
export const BuyButton: FC<BuyButton> = ({ approved, token, onClick, onApprove, canBuy, state, disabled }) => {
  const isApproved = approved === ApprovalState.APPROVED;
  let isDisabled = isApproved ? false : !canBuy;
  isDisabled = isDisabled || disabled || state > RaffleState.STARTED;
  return (
    <RaffleButton
      text="Buy Tickets"
      disabled={isDisabled}
      approved={isApproved}
      onApprove={onApprove}
      onClick={onClick}
      token={isApproved ? null : token}
    />
  );
};
export default BuyButton;
