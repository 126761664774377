import React, { FC } from 'react';
import { ApprovalState } from '../../../../hooks/useApprove';
import RaffleButton from './Button.base';

export interface StakeButton {
  symbol: string;
  approval: ApprovalState;
  /** default onClick behavior */
  onClick: () => void;
  /** approve function for the onClick */
  onApprove: () => void;
}
export const StakeButton: FC<StakeButton> = ({ symbol, approval, onClick, onApprove }) => {
  const approved = approval === ApprovalState.APPROVED;
  return <RaffleButton text="Deposit" token={symbol} approved={approved} onClick={onClick} onApprove={onApprove} />;
};

export default StakeButton;
