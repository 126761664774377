import { DefaultConfigParams, RaffleConfiguration, RaffleConfigParams } from "../types";

// Testnet addresses
export const TESTNET = {
  FACTORY: '0xe567aa720533Db89cf732c9CdE085f12207401E5',
  STAKING: '0x5C30E66212A3E8dC1f0B8328717fA66EFBe7cbf6',
  RAFFLE: '0x5C1212a1e3f80379832B1ABfA8318A9AA8968b36',
  TREASURY:'0x123d0B51e462be28b2Eb88da8fa464f37907771d',
  REGISTRY:'0x0Eb525DA4155D86806dAFF258fFbF0644c0b03bD',
}

/**
 * @dev default configuration points contracts to the testnet.
 * the ABI is the same as mainnet but the address obviously require the override
 * from the final configuration to be used.
 *
 * If we are operating in test mode we'll natively use the testnet.
 */
const defaults: DefaultConfigParams = {
  adminRole: 0x00,
  pollingInterval: 5_000,
  address: {
    factory:  TESTNET.FACTORY,
    staking:  TESTNET.STAKING,
    treasury: TESTNET.TREASURY,
    registry: TESTNET.REGISTRY,
  },
  abi: {
    factory:  require('./abi/raffle_proxy_factory.abi.json').abi,
    staking:  require('./abi/staking.abi.json').abi,
    treasury: require('./abi/treasury.abi.json').abi,
    registry: require('./abi/raffle_partecipant_registry.abi.json').abi,
    raffle:   require('./abi/raffle.abi.json').abi,
  },
  provider: {
    rpc: 'https://rpc.testnet.fantom.network/',
    chainId: 4002,
    options: {
      keepAlive: true,
      timeout: 10000,
    }
  }
}

const mergeConfig = <T>(defaults: T, config: T): T => ({...defaults,...config });
// if testing is true will return the defaults, otherwise will merge the config objects
const overrides = <T>(testing: boolean, defaults: T, config: T) => testing ? {...defaults} : mergeConfig(defaults, config);
/**
 * Create the configuration object for the Raffle API & context.
 * Optional parameters will fallback to the default configuration.
 * */
export function createRaffleConfig(config: RaffleConfigParams): RaffleConfiguration {
  return ({
    ...defaults,
    ...config,
    address: overrides(config.testnet, defaults.address, config.address),
    abi: mergeConfig(defaults.abi, config.abi),
    provider: {
      ...overrides(config.testnet, defaults.provider, config.provider),
      options: overrides(config.testnet, defaults.provider.options, config.provider?.options),
    }
  });
}

export default createRaffleConfig;
