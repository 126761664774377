import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import {Theme} from '@material-ui/core';

export interface RaffleToolbar {
  children?: React.ReactNode;
  color?: 'dark' | 'light';
}

const useStyles = makeStyles<Theme, RaffleToolbar>((theme) => ({
  toolbar: {
    padding: theme.spacing(2),
    width: '100%',
    backgroundColor: ({color}) => theme.palette.primary[color === 'light'? 'light' : 'dark'],
  },
}));

export const RaffleToolbar: React.FC<RaffleToolbar> = ({ children, color = 'dark' }) => {
  const classes = useStyles({ color });

  return (
  <Toolbar className={classes.toolbar} variant="dense">
    {children}
  </Toolbar>
  )
}

export default RaffleToolbar;
