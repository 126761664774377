import React, { useContext } from 'react';
import { AlertCircle, CheckCircle, IconProps } from 'react-feather';
import styled, { ThemeContext } from 'styled-components';

export interface PopupIcon extends IconProps {
  success?: boolean;
}

export const PopupWrapper = styled.div`
  flex-wrap: nowrap;
`;

export const PopupIcon: React.FC<PopupIcon> = ({ success, size = 24, ...props }) => {
  const theme = useContext(ThemeContext);

  return (
    <IconWrapper>
      {success ? (
        <CheckCircle color={theme.color.teal[200]} size={size} {...props} />
      ) : (
        <AlertCircle color="#FF6871" size={size} {...props} />
      )}
    </IconWrapper>
  );
};

export interface PopupAction {
  text: string;
  href?: string;
  onClick?: () => void;
  target?: string;
}
export const PopupAction: React.FC<PopupAction> = ({ text, href, onClick, target }) => {
  return (
    <StyledLink href={href} onClick={onClick} target={target}>
      {text}
    </StyledLink>
  );
};

export interface PopupText {
  children: React.ReactNode;
}
export const PopupText: React.FC<PopupText> = ({ children }) => {
  return (
    <TextWrapper>
      <Message>{children}</Message>
    </TextWrapper>
  );
};

const IconWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.color.grey[500]};
  margin-left: 32px;
  padding: 16px 0;
`;

const TextWrapper = styled.div`
  margin-left: 32px;
  padding-bottom: 16px;
`;

const Message = styled.span`
  font-weight: 500;
  color: ${(props) => props.theme.color.grey[300]};
`;
