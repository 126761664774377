import React, { useEffect, useState /*, { Suspense }*/ } from 'react';

import { Box, Container, Grid, Typography } from '@material-ui/core';

// import GridItemSuspense from '../Raffle.suspense';
import { RaffleItem } from '.';
import { useRaffles, useRaffleAdmin } from '../../hooks';
import { RaffleState } from '../../enums';
import { Raffle } from '../../api';

export interface RaffleGrid {
  showStates?: RaffleState[];
}

const ALL_STATES: RaffleState[] = [RaffleState.STARTED, RaffleState.ENDED, RaffleState.CLOSED];

const RaffleGrid: React.FC<RaffleGrid> = ({ showStates = ALL_STATES }) => {
  const isAdmin = useRaffleAdmin();
  const [states, setStates] = useState(showStates);

  useEffect(() => {
    if (isAdmin && !showStates.includes(RaffleState.CREATED)) {
      setStates([RaffleState.CREATED, ...showStates]);
    }
  }, [isAdmin]);
  // admins need to be able to see all raffles
  const raffles = useRaffles(states);

  return <RaffleGridView raffles={raffles} />;
};

export default RaffleGrid;

export const RaffleGridView: React.FC<{ raffles: Raffle[] }> = ({ raffles = [] }) => {
  if (raffles.length === 0) {
    return <RafflesLoading/>;
  }
  return (
  <Grid container spacing={4} justify="center" alignItems="center">
    {raffles?.map((raffle, i) => (
      <Grid key={i} item xs={12} container justify="space-between">
        {/* <Suspense fallback={<GridItemSuspense />}> */}
        <RaffleItem raffle={raffle} />
        {/* </Suspense> */}
      </Grid>
    ))}
  </Grid>
);};

const RafflesLoading = () => (
  <Box>
    <Container>
      <Grid container alignContent='center' justify='center'>
        <Typography color="textPrimary" variant="h3">Loading...</Typography>
      </Grid>
    </Container>
  </Box>
)
