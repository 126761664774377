import React from 'react';
import { useWallet } from 'use-wallet';
import config from '../../config';
import { PopupWrapper, PopupIcon, PopupAction, PopupText } from './components';

export interface TransactionPopup {
  hash: string;
  success?: boolean;
  summary?: string;
}

export default function TransactionPopup({ hash, success, summary }: TransactionPopup) {
  const { chainId } = useWallet();

  return (
    <PopupWrapper>
      <PopupIcon success={success} />
      <PopupText>{summary ?? 'Hash: ' + hash.slice(0, 8) + '...' + hash.slice(58, 65)}</PopupText>
      {chainId && <PopupAction text="View on Etherscan" target="_blank" href={`${config.ftmscanUrl}/tx/${hash}`} />}
    </PopupWrapper>
  );
}
