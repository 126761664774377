// import React from 'react';
import { createGlobalStyle } from 'styled-components';

import MasonryImage from '../../assets/img/background.png';

const BackgroundImage = createGlobalStyle`
body, html {
  background: url(${MasonryImage}) no-repeat !important;
  background-size: cover !important;
}
`;

export default BackgroundImage;
