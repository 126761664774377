import React, { useCallback, useState } from 'react';
import { RaffleState } from '../../enums';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import useDialog from '../../hooks/useDialog';
import ConfirmationDialog from '../Dialog.confirm';

const stateTxt = (state: RaffleState) => RaffleState[state];

export interface StateSelect {
  value: RaffleState;
  onSelect: (state: RaffleState) => any;
}
export const StateSelect: React.FC<StateSelect> = ({ value, onSelect }) => {
  const [selected, setSelected] = useState<RaffleState>(value);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const _state = event.target.value as RaffleState;
      if (_state === value) return; // no need to update raffle state
      setSelected(_state);
      openDialog();
      // TODO: Use a dialog for this to be fancy
      // if (window.confirm(`Change the state to ${stateTxt(_state)}? this cannot be undone`)) onChange(_state);
    },
    [selected],
  );
  const STATES = [RaffleState.CREATED, RaffleState.STARTED, RaffleState.ENDED, RaffleState.CLOSED];

  const handleCloseDialog = useCallback(() => {
    closeDialog();
    setSelected(value);
  }, [selected, value]);

  const [openDialog, closeDialog] = useDialog(
    <ConfirmationDialog title="Updating raffle state" onClose={handleCloseDialog} onConfirm={() => onSelect(selected)}>
      <Typography>
        Change the state of this raffle to <strong>{stateTxt(selected)}</strong>?
      </Typography>
    </ConfirmationDialog>,
  );

  return (
    <FormControl fullWidth>
      <Select
        disabled={value === RaffleState.CLOSED}
        variant="outlined"
        value={value as number}
        onChange={handleChange}
      >
        {STATES.map((state) => (
          <MenuItem key={state} disabled={state <= value} value={state}>
            {stateTxt(state)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StateSelect;
