import { BigNumber } from "ethers";
import { useCallback, useEffect, useState } from "react";
import type { Raffle } from "../api";

/** Partial type ERC20 */
interface Token {
  symbol: string;
  balanceOf: (address: string) => Promise<BigNumber>,
  decimal: number;
};

export interface Balance {
  symbol: string;
  balance: number;
}

type Formatter = (v: BigNumber, d: number) => number;

/** @internal actual "hook" that performs the fetching and periodic update */
const useBalance = (token: Token, address: string, formatter: Formatter, refreshInterval: number): Balance => {
  const [balance, setBalance] = useState<number>(0);

  const fetchBalance = useCallback(async () => {
    setBalance(formatter(await token.balanceOf(address), token.decimal));
  }, [token, address]);

  useEffect(() => {
    fetchBalance();
    const interval = setInterval(fetchBalance, refreshInterval);
    return () => clearInterval(interval);
  }, [fetchBalance]);

  return { symbol: token.symbol, balance };
}
/** Get the provided raffle's utility token balance for the connected account */
export const useUtilityBalance = (raffle: Raffle) => {
  return useBalance(raffle.utilityToken, raffle.account, raffle.utils.BnToNumber, raffle.api.config.pollingInterval);
}

/** Get the provided raffle's staking token balance for the connected account */
export const useStakingBalance = (raffle: Raffle) => {
  return useBalance(raffle.stakingToken, raffle.account, raffle.utils.BnToNumber, raffle.api.config.pollingInterval);
}

/**
 * Fetches the balances of the utility and staking tokens for the given raffle
 * and return them as an object.
 * Balances are updated using the polling interval set in the raffle API config.
 * @returns [utilityBalance, stakingBalance] : [number, number]
 */
const useBalances = (raffle: Raffle): [Balance, Balance] => {
  return [
    useUtilityBalance(raffle),
    useStakingBalance(raffle),
  ];
}

export default useBalances;
