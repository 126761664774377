import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { RaffleGroup } from '../../types';
import * as utils from '../../utils';
import { Button } from '@material-ui/core';

const calculateTaxedAmount = (amount: number, tax: number) => {
  return amount - amount * (tax / 100);
};

interface RaffleRewardProps {
  group: RaffleGroup;
  taxRate?: number;
  isWinner?: boolean;
  hasTickets?: boolean;
  onClaim?: () => any;
  hasClaimed?: boolean;
}

export const RaffleReward: React.FC<RaffleRewardProps> = ({
  group,
  taxRate,
  isWinner,
  onClaim,
  hasTickets,
  hasClaimed,
}) => {
  const { amount, symbol, winners } = group;
  const showTax = hasTickets && !!taxRate;

  return (
    <Grid container direction="column" alignItems="center" justify="center">
      <Typography variant="subtitle1">{utils.text.getWinnerText(winners)} up to</Typography>
      <Typography variant="h4" align="center">
        <strong>
          {amount} {symbol}
        </strong>
      </Typography>
      {showTax && (
        <Typography variant="subtitle2" align="center">
          You can win {calculateTaxedAmount(amount, taxRate)} {symbol}
        </Typography>
      )}
    </Grid>
  );
};

export interface RaffleRewardDefault {
  winners: number;
  symbol: string;
  amount: number;
  tax: number;
  showTax?: boolean;
}
/**
 * Default view for the raffle prizes, gets shown when the raffle is not ended or
 * the user has not won the raffle
 */
export const RaffleRewardDefault: React.FC<RaffleRewardDefault> = ({ winners, symbol, amount, tax, showTax }) => {
  return (
    <>
      <Typography variant="subtitle1">{utils.text.getWinnerText(winners)} up to</Typography>
      <Typography variant="h4" align="center">
        <strong>
          {amount} {symbol}
        </strong>
      </Typography>
      {showTax && (
        <Typography variant="subtitle2" align="center">
          You can win {calculateTaxedAmount(amount, tax)} {symbol}
        </Typography>
      )}
    </>
  );
};
export interface RaffleRewardWinner {
  symbol: string;
  amount: number;
  tax: number;
  onClaim: () => any;
  hasClaimed: boolean;
}
/**
 * View for the raffle prizes, gets shown when the raffle is ended and the user
 * has won the raffle
 */
export const RaffleRewardWinner: React.FC<RaffleRewardWinner> = ({ amount, symbol, tax, onClaim, hasClaimed }) => {
  return (
    <>
      <Typography variant="subtitle1">You won!</Typography>
      <Typography variant="h4" align="center">
        <strong>{calculateTaxedAmount(amount, tax)}</strong> {symbol}
      </Typography>
      <Button fullWidth color="default" variant="contained" onClick={onClaim} disabled={!onClaim || hasClaimed}>
        Claim reward
      </Button>
    </>
  );
};

export default RaffleReward;
