import React, { FC, useEffect } from 'react';
import { DialogActions, DialogContent, DialogTitle, DialogContentText, TextField, Button } from '@material-ui/core';
import useClamped from '../../hooks/useClamped';
import ERC20 from '../../../../tomb-finance/ERC20';

export interface StakeDialog {
  /**
   * whether we're staking or unstaking.
   * use to handle texts
   */
  action: 'stake' | 'unstake';
  /** staking token name */
  token: ERC20;
  /** amount of currently staked tokens */
  staked: number;
  /** max amount allowed to stake in a single transaction
   * @dev this should/could be the current user balance but we have it capped on
   * the contract side...?
   */
  maxCount?: number;
  /** action on the stake/unstake button click */
  onConfirm: (amount: number) => any;
  onClose: () => void;
}

export const StakeDialog: FC<StakeDialog> = (props) => {
  const { action, token, staked, onClose, onConfirm, maxCount: maxCount = 1000 } = props;
  // clamped values varies whether we're staking or unstaking.
  // we can't unstake more than we have staked, min value is always 0.
  const staking = action === 'stake';

  const [selected, setSelected, setMaxClamped] = useClamped({
    min: 1,
    max: staking ? maxCount : staked,
  });
  useEffect(() => {
    setMaxClamped(staking ? maxCount : staked);
  }, [staked]);

  const title = staking ? `Deposit ${token.symbol}` : `Withdraw ${token.symbol}`;
  const confirmText = staking ? 'Deposit' : 'Withdraw';

  // TODO:

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText variant="body1" align="center">
          You have{' '}
          <strong>
            {maxCount} {token.symbol}
          </strong>
          .
        </DialogContentText>
        <TextField
          fullWidth
          autoFocus
          type="number"
          value={selected}
          color="secondary"
          variant="outlined"
          label="Ticket amount"
          onChange={(e) => setSelected(parseInt(e.target.value, 10))}
        />
      </DialogContent>

      <DialogContentText variant="body2" align="center" style={{ marginTop: '1rem' }}>
        You will have {staking ? staked + selected : staked - selected} {token.symbol} deposited after this operation
      </DialogContentText>

      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button onClick={() => onConfirm(selected)} variant="contained" color="secondary">
          {confirmText}
        </Button>
      </DialogActions>
    </>
  );
};

export default StakeDialog;
