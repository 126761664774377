import { useContext } from 'react';

import type RaffleApi from '../api';
import { RaffleApiContext } from '../context';

export const useRaffleApi = (): RaffleApi => {
  const { api } = useContext(RaffleApiContext);
  return api;
};

export default useRaffleApi;
