
/**
 * Test raffles deployed with the current factory contract (0xC213010ED4Fd1cf06141FDD26e1Eb30E207A6378)
 * That we need to hide for the end users
 */

export default [
  '0x562907fA4dDeFF8FFAdF4724789C1977ec4C94CE',
  '0x0DcC0281C29bAdFd123B86B9276f49c7942874F8',
  '0x8cE9B7bb3dCaEC46b7B6492b387B3A17042D5C97',
  '0xBe5D98F824ddD60088f991DefA26C27339e97E71',
  '0xaC7f38ed24f7A8E2719d7bF793a88A62D6E6AcF9',
]
